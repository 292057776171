import React from "react";
import { withApollo } from "react-apollo";

// Scaffolding
import ProcessScaffold from "../../ProcessScaffold.react";
import H2 from "../../H2.react";
import Input from "../../Input.react";
import InputMultiline from "../../InputMultiline.react";
import Dropdown from "../../Dropdown.react";

// Functions
import validate from "../../../../functions/validate";
import update_url from "../../../../functions/update_url";
import * as gqlHelpers from "../../../../functions/graphql_helpers";

// Queries
import upsertWorkflow from "../../../../queries/upsert_workflow";
import routeQuery from "../../../../queries/ui_route";

const tierOptions = [
	{
		text: "Tier 1: Basic",
		value: 1
	},
	{
		text: "Tier 2: Moderate",
		value: 2
	},
	{
		text: "Tier 3: Advanced",
		value: 3
	}
];

const basicPrimaryStyle = {
	width: "600px",
	maxWidth: "100%",
	display: "inline-block",
	textAlign: "left",
	padding: "16px 0px"
};

const tierStyle = {
	width: "300px"
};

const menuItems = [
	{
		text: "Basics",
		value: "basics"
	},
	{
		text: "Description",
		value: "description"
	},
	{
		text: "Tier",
		value: "tier"
	}
];

// Sets validation
const nameValidation = [
	{
		type: "min-length",
		value: 4
	}
];

class Basics extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this.state = { name: this.props.name || "", template: this.props.template || "" };
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
		this.props.updateFn(field, location, value);
	}

	render() {
		// Creates options
		var options = [{ text: "None", value: "" }];
		if (this.props.templates !== undefined) {
			for (var i = 0; i < this.props.templates.length; i++) {
				options.push({
					text: this.props.templates[i].name,
					value: this.props.templates[i].id
				});
			}
		}

		return (
			<div style={basicPrimaryStyle}>
				<H2 text={"Workflow Basic Details"} />
				<Input
					id="name"
					label="Workflow name"
					field="name"
					value={this.state.name}
					updateFn={this.props.updateFn}
					validation={nameValidation}
					errors={this.props.errors}
				/>
				<Dropdown
					id="preset-template"
					label="Use template"
					field="template"
					items={options}
					value={this.state.template}
					updateFn={this._updateFn}
					searchable={true}
				/>
			</div>
		);
	}
}

class Description extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this.state = { description: this.props.value || "" };
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
		this.props.updateFn(field, location, value);
	}

	render() {
		return (
			<div style={basicPrimaryStyle}>
				<H2 text={"Workflow Description"} />
				<InputMultiline
					field="description"
					value={this.state.description || ""}
					onBlur={this._updateFn}
					label="Description"
				/>
			</div>
		);
	}
}

class Tier extends React.Component {
	render() {
		return (
			<div style={basicPrimaryStyle}>
				<H2 text={"Workflow Tier"} />
				<Dropdown
					id="workflow-settings-tier"
					field="tier"
					value={this.props.value}
					updateFn={this.props.updateFn}
					label={"Tier"}
					items={tierOptions}
					style={tierStyle}
				/>
			</div>
		);
	}
}

class NewWorkflow extends React.Component {
	constructor(props) {
		super(props);
		this._onSectionChange = this._onSectionChange.bind(this);
		this._onDone = this._onDone.bind(this);
		this._updateFn = this._updateFn.bind(this);
		this.state = { section: "basics", tier: 1 };
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onSectionChange(section) {
		this.setState({ section });
	}

	async _onDone() {
		var nameError = validate(nameValidation, this.state.name);

		if (nameError === "") {
			this.setState({ validate: false });
			var update = {
				clientId: this.props.clientId,
				name: this.state.name,
				description: this.state.description || "",
				tier: this.state.tier || 1,
				editingVersion: 1,
				currentVersion: 1,
				maxVersion: 1,
				active: true,
				created: new Date(),
				updated: new Date()
			};

			var { data } = await gqlHelpers.mutate(
				this.props.client,
				upsertWorkflow,
				{ update, parentId: this.props.clientId || null },
				"Workflow"
			);

			if (data !== undefined && data.workflow !== undefined) {
				this.props.client.mutate({
					mutation: routeQuery,
					variables: {
						url: document.location.hash.substr(1, 10000) + "/" + data.workflow
					}
				});
			}
		} else this.setState({ validate: true });
	}

	render() {
		var toRender = [];
		if (this.state.section === "basics")
			toRender = (
				<Basics
					updateFn={this._updateFn}
					templates={this.props.templates}
					name={this.state.name || ""}
					template={this.state.template}
				/>
			);
		else if (this.state.section === "description")
			toRender = <Description updateFn={this._updateFn} value={this.state.description || ""} />;
		else if (this.state.section === "tier") toRender = <Tier updateFn={this._updateFn} value={this.state.tier} />;

		return (
			<ProcessScaffold
				title="New Workflow"
				onClose={this.props.onClose}
				menuItems={menuItems}
				section={this.state.section}
				onSectionChange={this._onSectionChange}
				onDone={this._onDone}
			>
				{toRender}
			</ProcessScaffold>
		);
	}
}

export default withApollo(NewWorkflow);
