import { GraphQLScalarType } from "graphql";

const FirebaseDate = new GraphQLScalarType({
	name: "FirebaseDate",
	description: "Firebase Date Object",
	parseValue(value) {
		console.log("HITS parseValue CUSTOM PARSER");
		return value; // value from the client
	},
	serialize(value) {
		console.log("HITS SERIALIZE CUSTOM PARSER");
		if (value._seconds !== undefined) return new Date(value._seconds * 1000);
		else return null;
	},
	parseLiteral(value) {
		console.log("HITS literal CUSTOM PARSER");

		return value; // value from the client
	}
});

export { FirebaseDate };
