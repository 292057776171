import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

// Actions
import UIActions from "../../actions/UIActions";

class Overlay extends React.Component {
	constructor(props) {
		super(props);
		this._onClose = this._onClose.bind(this);
		this.state = { noOverflow: false };
	}

	componentDidMount() {
		document.documentElement.style.overflow = "hidden";
	}

	componentWillUnmount() {
		document.documentElement.style.overflow = "auto";
	}

	_onClose() {
		if (this.props.onClose) this.props.onClose();
	}

	_onBlockPropogation(evemt) {
		evemt.stopPropagation();
	}

	render() {
		const primaryStyle = {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			width: "100%",
			height: "100vh",
			display: "inline-block",
			textAlign: "center",
			position: "fixed",
			top: "0px",
			left: "0px",
			zIndex: "4",
			overflowY: "auto"
		};

		const vertAlignStyle = {
			verticalAlign: "middle",
			display: "inline-block",
			width: "0px",
			height: "90vh"
		};

		return (
			<div style={primaryStyle} onClick={this._onClose}>
				<div style={vertAlignStyle} />
				<ReactCSSTransitionGroup
					transitionName="dialog"
					transitionAppear={true}
					transitionLeave={false}
					transitionEnter={false}
					transitionAppearTimeout={100}
				>
					{this.props.children}
				</ReactCSSTransitionGroup>
			</div>
		);
	}
}

export default Overlay;
