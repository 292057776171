import React from "react";

// Pages
import Summary from "./Summary.react";
import Single from "./single";

class PageLayer extends React.Component {
	render() {
		var view = this.props.localData.view;

		switch (view) {
			case "clients":
				return <Summary localData={this.props.localData} />;
			default:
				return <Single localData={this.props.localData} />;
		}
	}
}

class ClientsPage extends React.Component {
	render() {
		return <PageLayer key="home-page" localData={this.props.localData} />;
	}
}

export default ClientsPage;
