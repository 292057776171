import React from "react";

// Components
import GenericTable from "../_common/GenericTable.react";

// Query
import clientFlags from "../../../../queries/client_flags";

// Schemas
import flagsTableSchema from "../../../../schemas/client_flags";

class QueuedTickets extends React.Component {
	render() {
		return (
			<GenericTable
				components={this.props.components}
				key={"client-tickets-queued-" + this.props.item.id}
				clientId={this.props.item.id}
				title={"Flagged tickets"}
				query={clientFlags}
				where={[{ name: "clientId", operator: "=", value: this.props.item.id }]}
				orderBy={"date"}
				orderByDirection={"desc"}
				schema={flagsTableSchema}
				type={"flags"}
				view={this.props.localData.view}
			/>
		);
	}
}

export default QueuedTickets;
