/*import React from "react";
import { Query } from "react-apollo";

// Components
import AppBar from "../../../../_common/AppBar.react";
import Loading from "../../../../_common/Loading.react";

// Ticket Page
import Ticket from "./Ticket.react";

// Queries
import ticketQuery from "../../../../../queries/ticket";

class TicketPage extends React.Component {
	constructor(props) {
		super(props);
		var myLocation = document.location.hash.split("/");
		this.state = { myLocation };
	}

	render() {
		return (
			<Query
				query={ticketQuery}
				variables={{ id: this.state.myLocation[3], clientId: this.state.myLocation[1] }}
				pollInterval={10000}
			>
				{({ data: { ticket, client }, refetch }) => {
					console.log("TICKET LOADED");
					console.log(ticket);
					console.log(client);
					if (ticket === undefined) return <Loading key="loading-view" />;
					return [
						<AppBar
							key="app-bar"
							id={ticket.id}
							parentId={client.id}
							UserStore={this.props.user}
							text={""}
							refetch={refetch}
						/>,
						<Ticket
							key={"ticket-" + this.state.myLocation[3]}
							view={this.props.store.view}
							UIStore={this.props.store}
							item={ticket}
							myClient={client}
							user={this.props.user}
							refetch={refetch}
						/>
					];
				}}
			</Query>
		);
	}
}

export default TicketPage;
*/
export default "";
