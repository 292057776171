export default async (_, props, context, info) => {
	return await context.client.firestore.query(
		{
			path: "users/" + props.id,
			...props
		},
		"user",
		context,
		info,
		"User",
		"ROOT"
	);
};
