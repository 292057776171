import React from "react";

class MenuItem extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);
		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.updateFn(this.props.item.value);
	}

	render() {
		const primaryStyle = {
			padding: this.props.item.divider ? "" : "16px",
			fontSize: "16px",
			lineHeight: "20px",
			fontFamily: "Roboto",
			fontWeight: "400",
			width: this.props.item.divider ? "100%" : "calc(100% - 32px)",
			backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.05)" : "transparent",
			transition: "all 0.3s ease",
			cursor: "pointer",
			borderBottom: this.props.item.divider ? "1px solid rgba(0, 0, 0, 0.54)" : ""
		};

		const iconStyle = {
			verticalAlign: "middle",
			marginRight: "16px",
			color: this.props.item.iconColor !== undefined ? this.props.item.iconColor : "inherit",
			fontSize: "24px"
		};

		return (
			<li style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} onClick={this._onClick}>
				{this.props.item.icon !== undefined ? (
					<i style={iconStyle} className="material-icons">
						{this.props.item.icon}
					</i>
				) : (
					""
				)}
				{this.props.item.text || ""}
			</li>
		);
	}
}

export default MenuItem;
