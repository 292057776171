import React from "react";
import { Query } from "react-apollo";
import config from "../../config.public";

// Scaffolding
import AppBar from "../_common/AppBar.react";
import SideNavigation from "../_common/SideNavigation.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import Container from "../_common/Container.react";
import { DataTableGenericSection } from "../_common/datatable";

// Helpers
import * as pages from "../../functions/pages";

// Queries
import userQuery from "../../queries/me";
import clientsAdminQuery from "../../queries/clients_admin";
import tableSchemaClients from "../../schemas/clients";

class ClientSummary extends React.Component {
	render() {
		return (
			<Query key="pl-clients-query" query={userQuery} variables={{ uid: this.props.localData.myUID }}>
				{({ data: { user } }) => [
					<AppBar key="app-bar" UserStore={user} text="Clients" />,
					<SideNavigation
						key="side-navigation"
						user={user}
						view={this.props.localData.view}
						pages={config.pages}
						userPages={pages.by_permissions(user)} //this.state.UserStore.pages
					/>,
					<OuterScaffold key="clients-summary-outer-scaffold" spaceForHeader={true}>
						<Container maxWidth="1000px" menuSpace={true} verticalAlign="top">
							<DataTableGenericSection
								key={"clients-summary"}
								clientId={this.props.clientId}
								title={"Clients"}
								query={clientsAdminQuery}
								where={[]}
								orderBy={"name"}
								orderByDirection={"asc"}
								schema={tableSchemaClients}
								type={"clients"}
								view={this.props.localData.view}
							/>
						</Container>
					</OuterScaffold>
				]}
			</Query>
		);
	}
}

export default ClientSummary;
