import gql from "graphql-tag";

// Queries
const baseQuery = gql`
	query getUser {
		routeHistory
	}
`;

export default async (_, __, context) => {
	var { routeHistory } = context.client.cache.readQuery({ query: baseQuery });

	console.log("GOING BACK");
	console.log(routeHistory);
	if (routeHistory.length > 1) window.history.go(-1);
	else
		context.client.mutate({
			mutation: gql`
				mutation myMutation {
					route(url: "/") @client
				}
			`
		});

	return;
};
