import React from "react";

// Components
import DataHeaderRow from "./DataHeaderRow.react";
import DataCell from "./DataCell.react";

const filterArrowStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	marginRight: "4px",
	fontSize: "16px",
	marginTop: "-2px",
	cursor: "pointer"
};

class TableHeader extends React.Component {
	render() {
		return (
			<DataHeaderRow>
				{this.props.schema.columns.map((item, index) => (
					<DataCell key={"header-" + index} width={item.width} size={item.size} center={item.center || false}>
						{item.filter && item.value === this.props.orderBy ? (
							this.props.orderByDirection === "asc" ? (
								<i
									className="material-icons"
									style={filterArrowStyle}
									onClick={() => this.props.onChangeOrder(item.value, "desc")}
								>
									arrow_upward
								</i>
							) : (
								<i
									className="material-icons"
									style={filterArrowStyle}
									onClick={() => this.props.onChangeOrder(item.value, "asc")}
								>
									arrow_downward
								</i>
							)
						) : (
							""
						)}
						{item.filter && item.value !== this.props.orderBy ? (
							<i
								className="material-icons"
								style={filterArrowStyle}
								onClick={() => this.props.onChangeOrder(item.value, "asc")}
							>
								arrow_upward
							</i>
						) : (
							""
						)}
						{item.filter && item.value === this.props.orderBy ? <b>{item.text}</b> : item.text}
					</DataCell>
				))}
			</DataHeaderRow>
		);
	}
}

export default TableHeader;
