const config = {
	core: {
		name: "Hioperator",
		logo: "/images/simple_logo.png",
		primaryColor: "rgba(0, 0, 0, 0.87)",
		secondaryColor: "rgba(0, 0, 0, 0)",
		titleFont: "Roboto",
		appBarBackgroundColor: "white",
		appBarFontColor: "rgba(0, 0, 0, 0.87)"
	},
	registration: {
		allowSignup: false,
		allowGoogle: false
	},
	pages: [
		{
			text: "Clients",
			value: "clients",
			icon: "loyalty",
			iconColor: "#067ab8",
			roles: ["admin"],
			pagesRequireID: true,
			pages: [
				{
					text: "Overview",
					value: "details",
					icon: "details",
					iconColor: "#067ab8",
					roles: ["admin"]
				},
				{
					text: "Permissions",
					value: "permissions",
					icon: "lock_open",
					iconColor: "#067ab8",
					roles: ["admin"]
				},
				{
					text: "Active tickets",
					value: "activetickets",
					icon: "visibility",
					iconColor: "#067ab8",
					badgeColor: "orange",
					roles: ["admin"]
				},
				{
					text: "Queued tickets",
					value: "queued",
					icon: "work_outline",
					iconColor: "#067ab8",
					badgeColor: "teal",
					roles: ["admin"]
				},
				{
					text: "Escalations",
					value: "escalations",
					icon: "error_outline",
					iconColor: "#067ab8",
					badgeColor: "indianred",
					roles: ["admin"]
				},
				{
					text: "CSAT review",
					value: "csat",
					icon: "sentiment_satisfied_alt",
					iconColor: "#067ab8",
					badgeColor: "purple",
					roles: ["admin"]
				},
				{
					text: "Events",
					value: "events",
					icon: "event_note",
					iconColor: "#067ab8",
					roles: ["admin"]
				},
				{
					text: "Flagged tickets",
					value: "flagged",
					icon: "outlined_flag",
					iconColor: "#067ab8",
					roles: ["admin"]
				},
				{
					text: "All tickets",
					value: "tickets",
					icon: "line_weight",
					iconColor: "#067ab8",
					roles: ["admin"],
					pagesRequireID: true
				},
				{
					text: "Export",
					value: "export",
					icon: "import_export",
					iconColor: "#067ab8",
					roles: ["admin"]
				},
				{
					text: "Analytics",
					value: "analytics",
					icon: "bar_chart",
					iconColor: "#067ab8",
					roles: ["admin"]
				},
				{
					text: "Documentation",
					value: "documentation",
					icon: "help",
					iconColor: "#067ab8",
					roles: ["admin"]
				},
				{
					text: "Workflows",
					value: "workflows",
					icon: "call_split",
					iconColor: "#067ab8",
					roles: ["admin"],
					pagesRequireID: true
				},
				{
					text: "Macros",
					value: "macros",
					icon: "email",
					iconColor: "#067ab8",
					roles: ["admin"],
					pagesRequireID: true
				},
				{
					text: "Products",
					value: "products",
					icon: "store",
					iconColor: "#067ab8",
					roles: ["admin"],
					pagesRequireID: true
				},
				{
					text: "Settings",
					value: "settings",
					icon: "settings",
					iconColor: "#067ab8",
					roles: ["admin"]
				}
			]
		},
		{
			text: "Gurus",
			value: "gurus",
			icon: "group",
			iconColor: "#067ab8",
			roles: ["admin"],
			globalPermissions: ["gurusPage"],
			pagesRequireID: true,
			pages: [
				{
					text: "Overview",
					value: "details",
					icon: "details",
					iconColor: "#067ab8",
					roles: ["admin"],
					globalPermissions: ["gurusPage"]
				},
				{
					text: "Permissions",
					value: "permissions",
					icon: "lock_open",
					iconColor: "#067ab8",
					roles: ["admin"],
					globalPermissions: ["gurusPage"]
				},
				{
					text: "Events",
					value: "events",
					icon: "event",
					iconColor: "#067ab8",
					badgeColor: "teal",
					roles: ["admin"],
					globalPermissions: ["gurusPage"]
				},
				{
					text: "Flagged tickets",
					value: "flagged",
					icon: "outlined_flag",
					iconColor: "#067ab8",
					roles: ["admin"],
					globalPermissions: ["gurusPage"]
				},
				{
					text: "Analytics",
					value: "analytics",
					icon: "bar_chart",
					iconColor: "#067ab8",
					roles: ["admin"],
					globalPermissions: ["gurusPage"]
				},
				{
					text: "Settings",
					value: "settings",
					icon: "settings",
					iconColor: "#067ab8",
					roles: ["admin"],
					globalPermissions: ["gurusPage"]
				}
			]
		},
		/*
		{
			text: "Workflow Templates",
			value: "workflows",
			icon: "call_split",
			iconColor: "#067ab8",
			roles: ["admin"],
			pagesRequireID: true,
			globalPermissions: ["workflowTemplates"]
		},*/
		{
			text: "Ticket Triage",
			value: "tickettriage",
			icon: "work",
			iconColor: "#067ab8",
			roles: ["admin"]
		},
		{
			text: "Ticket Workflows",
			value: "ticketworkflows",
			icon: "done",
			iconColor: "#067ab8",
			roles: ["admin"],
			pagesRequireID: true
		},
		{
			text: "Active Tickets",
			value: "activetickets",
			icon: "remove_red_eye",
			iconColor: "#067ab8",
			roles: ["admin"],
			globalPermissions: ["activetickets"]
		}
	],
	userMenu: [
		{
			text: "Settings",
			value: "settings"
		},
		{
			text: "Logout",
			value: "logout"
		}
	],
	gcf: {
		prod: {
			apiKey: "AIzaSyBdSfr9zEippJ8294JXaC6XqOmgTMUMNUQ",
			authDomain: "hioperator-prod.firebaseapp.com",
			databaseURL: "https://hioperator-prod.firebaseio.com",
			projectId: "hioperator-prod",
			storageBucket: "hioperator-prod.appspot.com",
			messagingSenderId: "329936486573",
			appId: "1:329936486573:web:36a516b8b87ece14"
		},
		dev: {
			apiKey: "AIzaSyAINJ2cSaSN1KWOicR8V5-rwORjGY9Urao",
			authDomain: "hioperator-dev.firebaseapp.com",
			databaseURL: "https://hioperator-dev.firebaseio.com",
			projectId: "hioperator-dev",
			storageBucket: "hioperator-dev.appspot.com",
			messagingSenderId: "720080792237"
		},
		staging: {
			apiKey: "AIzaSyD0VQ6-IKIy3R4YZmvg8X2rcRUArB7XTjY",
			authDomain: "hioperator-staging.firebaseapp.com",
			databaseURL: "https://hioperator-staging.firebaseio.com",
			projectId: "hioperator-staging",
			storageBucket: "hioperator-staging.appspot.com",
			messagingSenderId: "686732170043"
		}
	},
	permissions: [
		{
			text: "Full Access",
			page: "clients",
			items: [
				{
					icon: "done_all",
					text: "Full access (can do anything)",
					value: "full_access"
				}
			]
		},
		{
			text: "Tickets",
			items: [
				{
					icon: "done",
					text: "Workflows",
					value: "workflows",
					page: "ticketworkflows"
				},
				{
					icon: "work",
					text: "Triage",
					value: "triage",
					page: "tickettriage"
				},
				{
					icon: "accessibility_new",
					text: "Sandbox",
					value: "sandbox"
				}
			]
		},
		{
			text: "Client Page",
			page: "clients",
			items: [
				{
					icon: "details",
					text: "Overview",
					value: "clients_details"
				},
				{
					icon: "lock_open",
					text: "Permissions",
					value: "clients_permissions"
				},
				{
					icon: "visibility",
					text: "Active tickets",
					value: "clients_activetickets"
				},
				{
					icon: "work_outline",
					text: "Queue",
					value: "clients_queued"
				},
				{
					icon: "error_outline",
					text: "Escalations",
					value: "clients_escalations"
				},
				{
					icon: "sentiment_satisfied_alt",
					text: "CSAT review",
					value: "clients_csat"
				},
				{
					icon: "event_note",
					text: "Events",
					value: "clients_events"
				},
				{
					icon: "outlined_flag",
					text: "Flagged tickets",
					value: "clients_flagged"
				},
				{
					icon: "line_weight",
					text: "All tickets",
					value: "clients_tickets"
				},
				{
					icon: "import_export",
					text: "Export",
					value: "clients_export"
				},
				{
					icon: "bar_chart",
					text: "Analytics",
					value: "clients_analytics"
				},
				{
					icon: "help",
					text: "Documentation",
					value: "clients_documentation"
				},
				{
					icon: "call_split",
					text: "Workflows",
					value: "clients_workflows"
				},
				{
					icon: "email",
					text: "Macros",
					value: "clients_macros"
				},
				{
					icon: "store",
					text: "Products",
					value: "clients_products"
				},
				{
					icon: "settings",
					text: "Settings",
					value: "clients_settings"
				}
			]
		}
	]
};

export default config;
