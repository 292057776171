import React from "react";

import m from "../../functions/m";

class Card extends React.Component {
	render() {
		const primaryStyle = {
			backgroundColor: "white",
			width: "100%",
			maxWidth: "1000px",
			display: "inline-block",
			verticalAlign: "middle",
			textAlign: "left",
			boxShadow:
				"0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
			borderRadius: "2px",
			position: "relative",
			cursor: this.props.onClick !== undefined ? "pointer" : "",
			whiteSpace: "normal"
		};

		return (
			<div
				id={this.props.id}
				style={m(primaryStyle, this)}
				onClick={this.props.onClick}
				onMouseOver={this.props.onMouseOver}
				onMouseOut={this.props.onMouseOut}
			>
				{this.props.children}
			</div>
		);
	}
}

export default Card;
