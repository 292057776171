import React from "react";
import { Query } from "react-apollo";

// Components
import Dropdown from "../Dropdown.react";
import Button from "../Button.react";

// Data Table parts
import TableHeader from "./TableHeader.react";
import DataRow from "./DataRow.react";
import DataCell from "./DataCell.react";

const primaryStyle = {
	width: "100%",
	display: "inline-block"
};

const controlRowStyle = {
	width: "100%",
	display: "inline-block"
};

const addContainerStyle = {
	width: "184px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px"
};

const addButtonStyle = {
	color: "rgb(76, 167, 224)"
};

const paginationControlsRowStyle = {
	display: "inline-block",
	textAlign: "right",
	color: "rgba(0, 0, 0, 0.62)",
	fontSize: "14px",
	padding: "14px 0px",
	width: "calc(100% - 200px)"
};

const paginationDescriptionStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	marginRight: "44px"
};

const arrowStyle = {
	marginRight: "24px",
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "24px",
	cursor: "pointer"
};

const pageSizeDropdownStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	width: "64px",
	fontSize: "inherit",
	margin: "-12px -8px -10px"
};

const rowsOptions = [
	{
		text: "10",
		value: 10
	},
	{
		text: "25",
		value: 25
	},
	{
		text: "50",
		value: 50
	},
	{
		text: "75",
		value: 75
	},
	{
		text: "100",
		value: 100
	}
];

var get_query_name = query => {
	return query.definitions[0].selectionSet.selections[0].name.value;
};

const loadingRowStyle = {
	padding: "24px",
	width: "calc(100% - 48px)",
	color: "rgba(0, 0, 0, 0.67)"
};

var get_href = (href, props, row) => {
	if (href === undefined) return undefined;

	if (href.indexOf("{{") > -1) {
		var myPipe = href.substr(href.indexOf("{{") + 2);
		myPipe = myPipe.substr(0, myPipe.indexOf("}}"));
		if (myPipe.indexOf("row.") > -1) {
			var myField = myPipe.replace("row.", "");
			href = href.replace("{{" + myPipe + "}}", String(row[myField] || ""));
		} else {
			href = href.replace("{{" + myPipe + "}}", String(props[myPipe] || ""));
		}

		return get_href(href, props, row);
	} else return href;
};

class DataTable extends React.Component {
	constructor(props) {
		super(props);
		this._onChangePage = this._onChangePage.bind(this);
		this._onChangeOrder = this._onChangeOrder.bind(this);
		this._onChangePageSize = this._onChangePageSize.bind(this);
		this._updateFn = this._updateFn.bind(this);

		this.state = {
			cursor: null,
			where: this.props.where,
			orderBy: this.props.orderBy || null,
			orderByDirection: this.props.orderByDirection || null,
			queryName: get_query_name(this.props.query),
			page: 1,
			pageSize: 10
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentWillReceiveProps(nextProps) {
		var queryName = get_query_name(nextProps.query);
		if (queryName !== this.state.queryName) this.setState({ queryName });
	}

	_onChangePage(cursor, direction) {
		var page = this.state.page;
		if (direction === "next") page++;
		else if (page > 1) page--;

		if (page === 1) cursor = null;
		this.setState({ cursor, page });

		window.scrollTo(0, 0);
	}

	_onChangeOrder(orderBy, orderByDirection) {
		var page = 1;
		var cursor = null;
		this.setState({ orderBy, orderByDirection, page, cursor });
	}

	_onChangePageSize(field, location, value) {
		this.setState({ [field]: value, cursor: null, page: 1 });
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	render() {
		console.log("MY PAGE SIZE IS " + this.state.pageSize);
		return (
			<div style={primaryStyle}>
				{/* Makes table header */}
				<TableHeader
					schema={this.props.schema}
					onChangeOrder={this._onChangeOrder}
					orderBy={this.state.orderBy}
					orderByDirection={this.state.orderByDirection}
				/>

				{/* Query for rows */}
				<Query
					query={this.props.query}
					variables={{
						where: this.state.where,
						cursor: this.state.cursor,
						orderBy: this.state.orderBy,
						orderByDirection: this.state.orderByDirection,
						pageSize: this.state.pageSize
					}}
					pollInterval={10000}
					fetchPolicy="cache-and-network"
				>
					{({ data }) => {
						console.log(data);

						if (data === undefined || data[this.state.queryName] === undefined)
							return <div style={loadingRowStyle}>Loading...</div>;
						if (data[this.state.queryName][this.props.type].length === 0)
							return <div style={loadingRowStyle}>None found</div>;

						return (
							<div>
								{data[this.state.queryName][this.props.type].map((row, index) => (
									<DataRow
										key={"row-" + (row.id || index)}
										href={get_href(this.props.schema.href, this.props, row)}
										onClick={this.props.updateFn}
										item={row}
										preview={this.props.schema.preview !== undefined ? row[this.props.schema.preview] || "" : ""}
									>
										{this.props.schema.columns.map((cell, index) => (
											<DataCell
												key={"row-" + row.id + "-" + index}
												width={cell.width}
												size={cell.size}
												color={this.props.controls.get_cell_color(cell, row)}
												wrap={cell.wrap || false}
												html={cell.html}
												center={cell.center || false}
											>
												{this.props.controls.get_cell_value(cell, row)}
											</DataCell>
										))}
									</DataRow>
								))}

								{/* Pagination description and controls */}
								<div style={controlRowStyle}>
									<div style={addContainerStyle}>
										{this.props.onAdd ? (
											<Button style={addButtonStyle} text="Add" type="flat" onClick={this.props.onAdd} />
										) : (
											""
										)}
									</div>
									<div style={paginationControlsRowStyle}>
										<div style={paginationDescriptionStyle}>
											Rows per page:{" "}
											<Dropdown
												id="page-size-selector"
												style={pageSizeDropdownStyle}
												items={rowsOptions}
												value={this.state.pageSize}
												thin={true}
												simple={true}
												field={"pageSize"}
												updateFn={this._onChangePageSize}
											/>
										</div>
										<div style={paginationDescriptionStyle}>
											Showing {(this.state.page - 1) * this.state.pageSize + 1}-
											{(this.state.page - 1) * this.state.pageSize + data[this.state.queryName][this.props.type].length}{" "}
											of {data[this.state.queryName].cursor.total.toLocaleString()} {this.props.type}
										</div>

										{data[this.state.queryName].cursor.previousCursor !== null ? (
											<i
												className="material-icons"
												style={arrowStyle}
												onClick={() => this._onChangePage(data[this.state.queryName].cursor.previousCursor, "previous")}
											>
												keyboard_arrow_left
											</i>
										) : (
											""
										)}

										{data[this.state.queryName].cursor.nextCursor !== null ? (
											<i
												className="material-icons"
												style={arrowStyle}
												onClick={() => this._onChangePage(data[this.state.queryName].cursor.nextCursor, "next")}
											>
												keyboard_arrow_right
											</i>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						);
					}}
				</Query>
			</div>
		);
	}
}

export default DataTable;
