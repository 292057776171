const common = [
	"You are welcome!",
	"Thanks for reaching out!",
	"Thanks for the follow up.",
	"I am more than happy to help,",
	"I am sorry for any inconveniences this may have caused.",
	"Thank you for the feedback, I will pass it along to our fulfillment team.",
	"Please allow 5-10 business days for funds to show in your account.",
	"Please don't hesitate to reach out if there's anything else at all that we can do to help!",
	"Let me know if you have any other questions, happy to help!",
	"Cheers,",
	"Best,",
	"Have a lovely day,",
	"Have a lovely evening,",
	"Plant-based love,",
	"Hailey"
];

var query = text => {
	text = text.toLowerCase();
	for (var i = 0; i < common.length; i++) {
		if (common[i].toLowerCase().startsWith(text)) return common[i].substr(text.length, common[i].lengthI);
	}

	return null;
};

export { query };
