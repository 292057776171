import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

// Layers
//import PageLayer from "./components/_root/PageLayer.react";
//import OverlayLayer from "./components/_root/OverlayLayer.react";

// Pages
import Login from "./components/login";
import Home from "./components/home";
import Clients from "./components/clients";
import Gurus from "./components/gurus";
import TicketTriage from "./components/ticket_triage";
import TicketWorkflows from "./components/ticket_workflows";

// Components
import Snackbar from "./components/_common/Snackbar.react";
import Loading from "./components/_common/Loading.react";

const primaryStyle = {
	width: "100%",
	display: "inline-block",
	fontWeight: "400",
	fontFamily: "Roboto",
	color: "rgba(0, 0, 0, 0.87)"
};

class PageLayer extends React.Component {
	render() {
		var view = this.props.localData.view.split("_");
		view = view[0];

		console.log(this.props.localData);
		switch (view) {
			case "login":
				return <Login localData={this.props.localData} />;
			case "home":
				return <Home localData={this.props.localData} />;
			case "clients":
				return <Clients localData={this.props.localData} />;
			case "gurus":
				return <Gurus localData={this.props.localData} />;
			case "tickettriage":
				return <TicketTriage localData={this.props.localData} />;
			case "ticketworkflows":
				return <TicketWorkflows localData={this.props.localData} />;
			default:
				return <Login localData={this.props.localData} />;
		}
	}
}

class App extends React.Component {
	render() {
		console.log("RENDERING APP");
		console.log(this.props.store);

		return (
			<div style={primaryStyle}>
				{this.props.localData.view === "loading" ? (
					<Loading key="loading-view" />
				) : (
					<PageLayer localData={this.props.localData} />
				)}

				<ReactCSSTransitionGroup
					key="alerts-transition-group"
					transitionName="alerts"
					transitionAppear={false}
					transitionLeave={true}
					transitionEnter={true}
					transitionEnterTimeout={200}
					transitionLeaveTimeout={100}
				>
					{this.props.localData.messages.length > 0 ? (
						<Snackbar key="snackbar" item={this.props.localData.messages[0]} />
					) : (
						""
					)}
				</ReactCSSTransitionGroup>
			</div>
		);
	}
}

export default App;

/*
	<ReactCSSTransitionGroup
					id="overlay-transition-group"
					key="overlay-transition-group"
					transitionName="overlay"
					transitionAppear={false}
					transitionLeave={true}
					transitionEnter={true}
					transitionEnterTimeout={50}
					transitionLeaveTimeout={100}
				>
					<OverlayLayer store={this.props.store} />
                </ReactCSSTransitionGroup>
                */
