import React from "react";

// Pages
import GenericTable from "../_common/GenericTable.react";
import Single from "./single";

// Creators
import NewWorkflow from "../../../_common/creation/workflows/NewWorkflow.react";

// Query
import clientWorkflows from "../../../../queries/client_workflows";

// Schemas
import workflowsTableSchema from "../../../../schemas/client_workflows";

class PageLayer extends React.Component {
	render() {
		var view = this.props.localData.view.split("_");

		switch (view[2]) {
			case "details":
				return <Single localData={this.props.localData} />;
			default:
				return (
					<GenericTable
						components={this.props.components}
						clientId={this.props.item.id}
						title={"Workflows"}
						query={clientWorkflows}
						where={[{ name: "clientId", operator: "=", value: this.props.item.id }]}
						orderBy={"name"}
						orderByDirection={"asc"}
						schema={workflowsTableSchema}
						type={"workflows"}
						view={this.props.localData.view}
						creator={NewWorkflow}
					/>
				);
		}
	}
}

class WorkflowsPage extends React.Component {
	render() {
		return (
			<PageLayer
				key={"workflows-page-" + this.props.item.id}
				item={this.props.item}
				components={this.props.components}
				localData={this.props.localData}
			/>
		);
	}
}

export default WorkflowsPage;
