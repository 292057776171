function duration(start, finish) {
	finish = new Date(finish);
	start = new Date(start);
	var diff = (finish - start) / 1000;

	return parse_diff(diff);
}

function parse_diff(diff) {
	if (diff < 60) return Math.round(diff) + " seconds";
	else {
		var minutes = Math.floor(diff / 60);
		var seconds = Math.round(diff % 60);
		seconds = seconds < 10 ? "0" + seconds : seconds;

		if (minutes < 60) return minutes + ":" + seconds;
		else {
			var hours = Math.floor(minutes / 60);
			minutes = Math.round(minutes % 60);
			minutes = minutes < 10 ? "0" + minutes : minutes;
			return hours + ":" + minutes + ":" + seconds;
		}
	}
}

function parse_time(obj, returnTimestamp) {
	var myTime = "";
	if ([null, undefined].indexOf(obj) === -1 && typeof obj === "object" && obj.seconds !== undefined)
		myTime = new Date(obj.seconds * 1000);
	else if ([null, undefined].indexOf(obj) === -1 && typeof obj === "object" && obj._seconds !== undefined)
		myTime = new Date(obj._seconds * 1000);
	else if (!isNaN(obj)) myTime = new Date(obj * 1000);
	else if ([null, undefined].indexOf(obj) === -1) myTime = new Date(obj);

	if (returnTimestamp) return myTime;

	myTime = myTime !== "" ? myTime.toLocaleString() : "";
	return myTime;
}

function get_time(value, timezone) {
	// Figures out offset from timezone for current location
	var now = new Date(value);
	var target = now.toLocaleString("en-US", { timeZone: timezone });
	var offset = new Date(target) - now - now.getTimezoneOffset() * 60 * 1000;

	var newDate = Number(new Date(value)) - offset;
	return Number(newDate);
}

export { duration, parse_diff, parse_time, get_time };
