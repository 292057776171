import firebase from "firebase/app";
import "firebase/auth";

export default async (_, data, context) => {
	firebase
		.auth()
		.signOut()
		.then(
			function() {
				context.client.resetStore();
				window.location.href = "/";
				// Sign-out successful.
			},
			function(error) {
				// An error happened.
			}
		);
	return;
};
