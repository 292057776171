import gql from "graphql-tag";

export default async (_, __, context) => {
	window.onpopstate = function(event) {
		context.client.mutate({
			mutation: gql`
				mutation myMutation {
					route(url: null) @client
				}
			`
		});
	};

	return;
};
