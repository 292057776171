import firebase from "firebase/app";
import "firebase/auth";

import gql from "graphql-tag";

const myQuery = gql`
	mutation logout {
		show(view: "login") @client
		addMessage(text: "We just emailed you a password reset link. Check your email.", type: "success") @client
	}
`;

export default async (_, props, context) => {
	console.log("GOT HERE");
	console.log(props);
	var result = await firebase
		.auth()
		.sendPasswordResetEmail(props.email)
		.then(() => true)
		.catch(function(error) {
			return error;
		});

	console.log(result);
	await context.client.mutate({ mutation: myQuery });
	return;
};
