import React from "react";

// Functions
import m from "../../functions/m";

class H1 extends React.Component {
	render() {
		const primaryStyle = {
			display: "inline-block",
			width: "100%",
			fontFamily: "Roboto",
			fontWeight: "900",
			fontSize: this.props.fontSize || "34px",
			textAlign: this.props.textAlign || "left",
			margin: this.props.margin || "16px 0px"
		};

		return <h1 style={m(primaryStyle, this)}>{this.props.text}</h1>;
	}
}

export default H1;
