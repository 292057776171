import gql from "graphql-tag";

var mutate = async (client, mutation, variables, type) => {
	client.mutate({
		mutation: gql`
			mutation showSaver {
				saver(value: "in progress") @client
			}
		`
	});

	return await client
		.mutate({
			mutation,
			variables
		})
		.then(results => {
			client.mutate({
				mutation: gql`
					mutation showSaver {
						saver(value: "done") @client
					}
				`
			});

			return results;
		})
		.catch(e => {
			console.log(e);
			client.mutate({
				mutation: gql`
					mutation addMessage($text: String) {
						addMessage(text: $text, type: "error") @client
						saver(value: "error") @client
					}
				`,
				variables: { text: type + "  save failed :(" }
			});
		});
};

var showOverlay = (client, overlay, overlayDetails) => {
	client.mutate({
		mutation: gql`
			mutation showOverlay($overlay: String, $clientId: ID, $ticketId: ID) {
				showOverlay(overlay: $overlay, clientId: $clientId, ticketId: $ticketId) @client
			}
		`,
		variables: { overlay, clientId: overlayDetails.clientId || null, ticketId: overlayDetails.ticketId || null }
	});
};

var addMessage = (client, text, type) => {
	client.mutate({
		mutation: gql`
			mutation addMessage($text: String, $type: String) {
				addMessage(text: $text, type: $type) @client
			}
		`,
		variables: { text, type }
	});
};

export { mutate, showOverlay, addMessage };
