import gql from "graphql-tag";

export default gql`
	mutation upsertPermittedGuru($update: Update, $parentId: ID) {
		permittedGuru(update: $update, parentId: $parentId) {
			id
			name
			email
			picture
			active
			lastLogin
			lastLogout
		}
	}
`;
