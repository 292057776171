import gql from "graphql-tag";

export default gql`
	query getPermissions($userId: ID!, $clientId: ID!) {
		userPermissions(userId: $userId, clientId: $clientId) {
			id
			value
		}
	}
`;
