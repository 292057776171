import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

// Components
import Card from "../Card.react";
import H1 from "../H1.react";
import Search from "../Search.react";

// Data Table parts
import DataTable from "./DataTable.react";

// Functions
import * as datatableHelpers from "../../../functions/datatable_helpers";

const cardStyle = {
	padding: "0px 0px",
	margin: "8px 0px"
};

const searchStyle = {
	width: "calc(100% - 96px)",
	margin: "0px 0px 16px 48px"
};

const titleStyle = {
	width: "calc(100% - 70px)",
	marginLeft: "48px",
	marginTop: "32px"
};

class CreatorOverlay extends React.Component {
	render() {
		return (
			<ReactCSSTransitionGroup
				id="overlay-transition-group"
				key="overlay-transition-group"
				transitionName="overlay"
				transitionAppear={false}
				transitionLeave={true}
				transitionEnter={true}
				transitionEnterTimeout={50}
				transitionLeaveTimeout={100}
			>
				{this.props.show ? <this.props.creator clientId={this.props.clientId} onClose={this.props.onClose} /> : ""}
			</ReactCSSTransitionGroup>
		);
	}
}

class DataTableCard extends React.Component {
	constructor(props) {
		super(props);
		this._onAdd = this._onAdd.bind(this);
		this._onCloseOverlay = this._onCloseOverlay.bind(this);
		this.state = { showCreator: false };
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onAdd() {
		this.setState({ showCreator: true });
	}

	_onCloseOverlay() {
		this.setState({ showCreator: false });
	}

	render() {
		var { title, ...other } = this.props;

		return (
			<Card style={cardStyle}>
				<H1 text={this.props.title.toUpperCase()} style={titleStyle} />
				{this.props.search !== undefined ? (
					<Search
						placeholder={"Search " + this.props.search.type + " to add..."}
						id={this.props.search.type + "-search"}
						onSelect={this.props.search.onSelect}
						type={this.props.search.type}
						min={2}
						style={searchStyle}
					/>
				) : (
					""
				)}
				<DataTable
					{...other}
					controls={datatableHelpers}
					onAdd={this.props.creator !== undefined ? this._onAdd : undefined}
				/>

				{/* Creator overlays */}
				<CreatorOverlay
					creator={this.props.creator}
					show={this.state.showCreator}
					clientId={this.props.clientId}
					onClose={this._onCloseOverlay}
				/>
			</Card>
		);
	}
}

export default DataTableCard;
