import * as custom_scalars from "./custom_scalars";

import * as ui from "../resolvers/ui";
import * as users from "../resolvers/users";
import * as dbsource from "../resolvers/firestore";

export default {
	Mutation: {
		update: async (_, data, context) => {
			context.client.writeData({ data });
			return;
		},
		session: dbsource.mutate_session,
		user: dbsource.mutate_user,
		addMessage: ui.addMessage,
		removeMessage: ui.removeMessage,
		show: ui.show,
		monitor: ui.monitor,
		route: ui.route,
		goBack: ui.goBack,
		showOverlay: ui.showOverlay,
		saver: ui.saver,
		auth: users.auth,
		login: users.login,
		logout: users.logout,
		resetPassword: users.resetPassword,
		client: dbsource.mutate_client,
		permission: dbsource.mutate_permission,
		deletePermission: dbsource.mutate_delete_permission
	},
	Query: {
		token: users.token,
		users: dbsource.query_users,
		user: dbsource.query_user,
		clients: dbsource.query_clients,
		client: dbsource.query_client,
		workflowTemplates: dbsource.query_workflow_templates,
		permission: dbsource.query_permission
	},
	Client: {
		tickets: dbsource.client_tickets,
		gurus: dbsource.client_gurus
	},
	Ticket: {
		comments: dbsource.ticket_comments
	},
	User: {
		permissions: dbsource.user_permissions
	},
	Permission: {
		user: dbsource.permission_user
	},
	FirebaseDate: custom_scalars.FirebaseDate
};
