export default async (parent, { limit = 25, where = [], orderBy = [] }, context, info) => {
	return await context.client.firestore.query(
		{
			path: "clients/" + parent.clientId + "/tickets/" + parent.id + "/comments",
			/*
            path: [
                { value: "clients", type: "collection" },
                { value: parent.clientId, type: "object" },
                { value: "tickets", type: "collection" },
                { value: parent.id, type: "object" },
                { value: "comments", type: "collection" }
            ]*/ limit: 5
		},
		"comments",
		context,
		info,
		"Comment",
		"Ticket",
		parent.id
	);
};
