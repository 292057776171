import gql from "graphql-tag";

export default gql`
	query searchMacrosWorkflows($term: String, $clientId: ID!) {
		searchMacros(term: $term, clientId: $clientId, active: true) {
			id
			name
		}
		searchWorkflows(term: $term, clientId: $clientId, active: true) {
			id
			name
		}
	}
`;
