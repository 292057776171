import React from "react";

// Actions

// Scaffolding
import OuterScaffold from "../_common/OuterScaffold.react";
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";

// Form items
import Input from "../_common/Input.react";
import Button from "../_common/Button.react";

// Headings
import H1 from "../_common/H1.react";
import H3 from "../_common/H3.react";

// Functions
import validate from "../../functions/validate";

// Sets validation
const passwordValidation = [
	{
		type: "min-length",
		value: 8
	}
];

class NewPassword extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = {
			password: "",
			code: "",
			loading: false,
			validate: false
		};
	}

	componentDidMount() {
		document.getElementById("code").focus();
	}

	_onClick() {
		var passwordError = validate(passwordValidation, this.state.password);

		if (passwordError === "") {
			this.setState({ loading: true, validate: false });
			//UserActions.updatePassword({password: this.state.password, code: this.state.code});
		} else this.setState({ validate: true });
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
	}

	_onCodeReturn() {
		document.getElementById("password").focus();
	}

	_onBack() {
		//UIActions.show("login");
	}

	render() {
		const cardStyle = {
			width: "calc(100% - 16px)",
			padding: "16px",
			textAlign: "center"
		};

		const rowStyle = {
			width: "100%",
			textAlign: "right",
			margin: "16px 0px 0px 0px"
		};

		const logoStyle = {
			height: "100px",
			marginBottom: "30px"
		};

		const forgotStyle = {
			marginRight: "16px"
		};

		return (
			<OuterScaffold>
				<Container maxWidth="500px">
					<img src={"/images/logo_white_bg@2x.png"} style={logoStyle} alt="" />
					<Card style={cardStyle}>
						<H1 text="Set New Password" textAlign="center" margin="0px" />
						<H3
							text="Please enter the verification code we just emailed you and your new password."
							textAlign="center"
							fontSize="20px"
							lineHeight="24px"
							margin="16px 0px"
						/>
						<Input
							id="code"
							label="Verification code"
							field="code"
							updateFn={this._updateFn}
							onReturn={this._onCodeReturn}
							errors={this.props.errors}
						/>
						<Input
							id="password"
							label="Password"
							type="password"
							field="password"
							updateFn={this._updateFn}
							validate={this.state.validate}
							validation={passwordValidation}
							onReturn={this._onClick}
							errors={this.props.errors}
						/>
						<div style={rowStyle}>
							<Button text="Back" style={forgotStyle} onClick={this._onBack} type="flat" />
							<Button text="SET NEW PASSWORD" onClick={this._onClick} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</OuterScaffold>
		);
	}
}

export default NewPassword;
