export default async (parent, { id }, context, info) => {
	var data = await context.client.firestore.query(
		{
			path: "clients/" + id
			//path: [{ value: "clients", type: "collection" }, { value: id, type: "object" }]
		},
		"client",
		context,
		info,
		"Client",
		"ROOT"
	);

	return data;
};
