export default async (_, props, context, info) => {
	console.log("UPDATING PERMISSION");
	console.log(props);
	await context.client.firestore.delete(
		{
			path: "permissions/" + props.id,
			...props
		},
		context,
		info
	);
};
