import config from "../config.public";

var find_iterate = (myRoute, pages) => {
	const single = myRoute.splice(0, 1)[0];

	for (var i = 0; i < pages.length; i++) {
		if (pages[i].value === single) {
			if (myRoute.length === 0 || pages[i].pages === undefined) return pages[i];
			else return find_iterate(myRoute, pages[i].pages);
		}
	}

	return {};
};

var find = page => {
	var myRoute = page.split(" ");
	return find_iterate(myRoute, config.pages);
};

var find_permission = (permissions, value) => {
	return permissions.find(item => item.value);
};

var by_permissions = me => {
	var i, j, client;
	var myPages = [];

	for (var k = 0; k < me.clients.length; k++) {
		client = me.clients[k];

		for (i = 0; i < config.permissions.length; i++) {
			for (j = 0; j < config.permissions[i].items.length; j++) {
				if (find_permission(client.permissions, config.permissions[i].items[j].value) !== undefined) {
					if (
						config.permissions[i].items[j].page !== undefined &&
						myPages.indexOf(config.permissions[i].items[j].page) === -1
					)
						myPages.push(config.permissions[i].items[j].page);

					if (config.permissions[i].page !== undefined && myPages.indexOf(config.permissions[i].page) === -1)
						myPages.push(config.permissions[i].page);
				}
			}
		}
	}

	for (i = 0; i < config.pages.length; i++) {
		if (config.pages[i].globalPermissions !== undefined) {
			for (j = 0; j < config.pages[i].globalPermissions.length; j++) {
				if (me[config.pages[i].globalPermissions[j]] === true && myPages.indexOf(config.pages[i].value) === -1)
					myPages.push(config.pages[i].value);
			}
		}
	}

	return myPages;
};

export { find, by_permissions };
