import React from "react";

// Actions
//import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "./Card.react";
import Overlay from "./Overlay.react";
import Container from "./Container.react";
import Stepper from "./Stepper.react";

// Buttons
import Button from "./Button.react";

const cardStyle = {
	width: "100%",
	padding: "0px"
};

const buttonRowStyle = {
	width: "calc(100% - 32px)",
	padding: "16px",
	borderTop: "1px solid lightgrey",
	textAlign: "right",
	display: "inline-block"
};

const buttonStyle = {
	marginLeft: "8px"
};

const menuContainerStyle = {
	display: "inline-block",
	borderBottom: "1px solid lightgrey",
	width: "calc(100% - 64px)",
	padding: "0px 32px",
	color: "white",
	backgroundColor: "rgb(102, 51, 153)"
};

const titleStyle = {
	width: "100%",
	padding: "16px 0px 0px",
	fontSize: "30px"
};

const bodyContainerStyle = {
	display: "inline-block",
	padding: "0px 16px",
	width: "calc(100% - 32px)",
	backgroundColor: "rgba(0, 0, 0, 0.07)",
	textAlign: "center",
	minHeight: "40vh"
};

class ProcessScaffold extends React.Component {
	constructor(props) {
		super(props);
		this._onClose = this._onClose.bind(this);
		this._onNext = this._onNext.bind(this);
		this._onDone = this._onDone.bind(this);
	}

	_onClose() {
		//UIActions.showOverlay("");
	}

	_onNext() {
		for (var i = 0; i < this.props.menuItems.length; i++) {
			if (this.props.menuItems[i].value === this.props.section) {
				this.props.onSectionChange(this.props.menuItems[i + 1].value);
				break;
			}
		}
	}

	_onDone() {
		if (this.props.onDone !== undefined) this.props.onDone();
		//UIActions.showOverlay("");
	}

	render() {
		var selectedIndex = 0;
		for (var i = 0; i < this.props.menuItems.length; i++) {
			if (this.props.menuItems[i].value === this.props.section) {
				selectedIndex = i;
				break;
			}
		}

		const lastStep = selectedIndex === this.props.menuItems.length - 1;

		return (
			<Overlay onClose={this.props.onClose}>
				<Container maxWidth="1100px" verticalAlign={"middle"} marginTop={this.props.marginTop || "0vh"}>
					<Card style={cardStyle}>
						<div style={menuContainerStyle}>
							<div style={titleStyle}>{this.props.title}</div>
							<Stepper
								items={this.props.menuItems}
								value={this.props.section}
								onSectionChange={this.props.onSectionChange}
							/>
						</div>
						<div style={bodyContainerStyle}>{this.props.children}</div>
						<div style={buttonRowStyle}>
							<Button text={"Cancel"} style={buttonStyle} onClick={this.props.onClose} type={"flat"} />
							{!this.props.noNext ? (
								<Button
									text={lastStep ? "Done" : "Next"}
									style={buttonStyle}
									onClick={lastStep ? this._onDone : this._onNext}
								/>
							) : (
								""
							)}
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default ProcessScaffold;
