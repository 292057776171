import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider, Query, withApollo } from "react-apollo";
import gql from "graphql-tag";

import "./index.css";
import "./functions/firebase";
import graphql from "./graphql/index";
import "./resolvers/users/auth";

import registerServiceWorker from "./registerServiceWorker";
import App from "./App";

var client = graphql();

const authMutation = gql`
	mutation auth {
		auth @client
	}
`;

client.mutate({ mutation: authMutation });

const rootQuery = gql`
	query getView {
		view @client
		messages @client {
			id
			text
			type
		}
		loggedIn @client
		myUID @client
	}
`;

const AppWithClient = withApollo(App);

ReactDOM.render(
	<ApolloProvider client={client}>
		<Query query={rootQuery}>
			{({ data: localData }) => {
				return <AppWithClient localData={localData} />;
			}}
		</Query>
	</ApolloProvider>,
	document.getElementById("root")
);
registerServiceWorker();
