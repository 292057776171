import gql from "graphql-tag";

export default gql`
	query getClientWorkflows(
		$cursor: String
		$where: Array
		$orderBy: String
		$orderByDirection: String
		$pageSize: Int
	) {
		clientWorkflows(
			cursor: $cursor
			where: $where
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			pageSize: $pageSize
		) {
			cursor {
				total
				nextCursor
				previousCursor
			}
			workflows {
				id
				name
				active
				created
				updated
			}
		}
	}
`;
