export default async (_, props, context, info) => {
	await context.client.firestore.update(
		{
			path: "users/" + props.id,
			...props
		},
		context,
		info
	);
};
