import gql from "graphql-tag";

export default gql`
	query getClientGurus($cursor: String, $where: Array, $orderBy: String, $orderByDirection: String, $pageSize: Int) {
		clientGurus(
			cursor: $cursor
			where: $where
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			pageSize: $pageSize
		) {
			cursor {
				total
				nextCursor
				previousCursor
			}
			gurus {
				id
				name
				email
				picture
				active
				lastLogin
				lastLogout
			}
		}
	}
`;
