import * as status from "./status";
import graphql from "../graphql/index";
import gql from "graphql-tag";

import config from "../config.public";

var client = graphql();

function get_url(url) {
	var hostname = document.location.hostname;

	if (hostname.indexOf("localhost") > -1) return "http://localhost:5000/" + config.gcf.prod.projectId + "/us-central1";
	else return "https://" + hostname.replace("app.", "api.");
}

var api = async (method, url, payload, callbackSuccess, callbackFailed, obj, statusCode, data, notify) => {
	var user = await client.query({
		query: gql`
			query getToken {
				token @client
			}
		`
	});

	user = user.data;

	if (statusCode !== undefined && statusCode !== null && obj !== null && obj !== undefined) status.add(obj, statusCode);

	// Sets headers
	var fetchData = {
		method: method,
		body: payload !== null ? payload : undefined,
		headers: { Authorization: "Bearer " + user.token }
	};

	url = get_url(url) + url;
	var body = payload !== null ? JSON.stringify(payload) : undefined;
	var headers = fetchData.headers;

	console.log("api call to " + url);
	// Makes call
	fetch(url, { body, method, headers })
		.then(resp => {
			status.remove(obj, statusCode);
			if (resp.status === 200) return resp.json();
			else throw resp;
		})
		.then(function(result) {
			if (typeof result === "string") result = JSON.parse(result);
			if ([null, undefined].indexOf(callbackSuccess) === -1) callbackSuccess(result, data);
		})
		.catch(err => {
			console.log("api error!");
			console.log(err);
			if (err.json !== undefined && [null, undefined].indexOf(callbackFailed) === -1) {
				err.json().then(errorMessage => {
					callbackFailed(errorMessage.error, data);
				});
			}
		});
};

export default api;
