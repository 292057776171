import gql from "graphql-tag";

export default gql`
	query getClientCsatReview(
		$cursor: String
		$where: Array
		$orderBy: String
		$orderByDirection: String
		$pageSize: Int
	) {
		clientCsatReview(
			cursor: $cursor
			where: $where
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			pageSize: $pageSize
		) {
			cursor {
				total
				nextCursor
				previousCursor
			}
			tickets {
				id
				subject
				description
				externalId
				status
				escalate
				externalSla
				sla
				requesterEmail
				requesterUpdated
				workflows {
					id
					status
				}
			}
		}
	}
`;
