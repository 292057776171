module.exports = {
	columns: [
		{
			text: "",
			size: "xs",
			value: "picture",
			type: "picture",
			width: "50px",
			center: true
		},
		{
			text: "Name",
			size: "s",
			value: "name",
			filter: true
		},
		{
			text: "Email",
			size: "s",
			value: "email"
		},
		{
			text: "Active",
			size: "xs",
			value: "active",
			type: "boolean",
			center: true,
			wrap: true
		},
		{
			text: "Last Login",
			size: "m",
			width: "150px",
			value: "lastLogin",
			type: "date"
		},
		{
			text: "Last Logout",
			size: "m",
			width: "150px",
			value: "lastLogout",
			type: "date"
		}
	]
};
