import gql from "graphql-tag";

import updateUrl from "../../functions/update_url";

// Queries
const viewQuery = gql`
	query getView {
		view @client
		viewData @client
	}
`;

export default async (_, { view, url, data }, context) => {
	var currentView = context.cache.readQuery({ query: viewQuery });
	if (view !== currentView.view || document.location.hash.indexOf(url) === -1) {
		context.client.writeData({ data: { view: view || "", viewData: data || null } });
		if ([undefined, null].indexOf(url) === -1) updateUrl(url);
	}

	return;
};
