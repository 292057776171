import React from 'react';


class H3 extends React.Component {
    render (){
        const primaryStyle = {
            display: "inline-block",
            width: this.props.width || "100%",
            fontFamily: "Roboto",
            fontWeight: "100",
            fontSize: this.props.fontSize || "24px",
            textAlign: this.props.textAlign || "left",
            margin: this.props.margin || "8px 0px",
            lineHeight: this.props.lineHeight || "34px"
        };

        return (
            <h1 style={primaryStyle}>{this.props.text}</h1>
        )
    }
}

export default H3;