import React from "react";
import { Query } from "react-apollo";
import config from "../../../config.public";

// Components
import AppBar from "../../_common/AppBar.react";
import SideNavigation from "../../_common/SideNavigation.react";
import Loading from "../../_common/Loading.react";

import ClientHeader from "./ClientHeader.react";

// Pages
import Overview from "./overview";
import Permissions from "./permissions";
import Active from "./active";
import Queued from "./queued";
import Escalations from "./escalations";
import Tickets from "./tickets";
import Events from "./events";
import CSATS from "./csats";
import Flagged from "./flagged";
import Exports from "./exports";
import Analytics from "./analytics";
import Documentation from "./documentation";
import Workflows from "./workflows";
import Macros from "./macros";
import Products from "./products";
import Settings from "./settings";

// Queries
import userQuery from "../../../queries/me";
import clientQuery from "../../../queries/client";

// Functions
import * as permissions from "../../../functions/permissions";

const settingsSubsection = ["clients_generic_integration", "clients_generic_crm", "clients_queue_order"];

const clientMenuOptions = [
	{
		text: "About Client",
		value: "about client"
	},
	{
		divider: true
	},
	{
		text: "Delete",
		value: "delete"
	}
];

var clientPages = [];
for (var i = 0; i < config.pages.length; i++) {
	if (config.pages[i].value === "clients") {
		clientPages = config.pages[i].pages;
		break;
	}
}

class PageLayer extends React.Component {
	render() {
		var view = this.props.localData.view.split("_");
		switch (view[1]) {
			case "details":
			default:
				return (
					<Overview
						components={this.props.components}
						key={"client-details-" + this.props.clientId}
						item={this.props.client || {}}
						refetch={this.props.refetch}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "permissions":
				return (
					<Permissions
						key={"client-permissions-" + this.props.clientId}
						components={this.props.components}
						clientId={this.props.clientId}
						user={this.props.user}
					/>
				);
			case "activetickets":
				return (
					<Active
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "queued":
				return (
					<Queued
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "escalations":
				return (
					<Escalations
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "csat":
				return (
					<CSATS
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "events":
				return (
					<Events
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "flagged":
				return (
					<Flagged
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "tickets":
				return (
					<Tickets
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "export":
				return (
					<Exports
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "analytics":
				return (
					<Analytics
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "documentation":
				return (
					<Documentation
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "workflows":
				return (
					<Workflows
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "macros":
				return (
					<Macros
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "products":
				return (
					<Products
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
			case "settings":
				return (
					<Settings
						key={"client-active-" + this.props.clientId}
						components={this.props.components}
						item={this.props.client || {}}
						localData={this.props.localData}
						user={this.props.user}
					/>
				);
		}
	}
}

class Client extends React.Component {
	constructor(props) {
		super(props);
		var myLocation = document.location.hash.split("/");
		this.state = { clientId: myLocation[1] || "" };
	}

	render() {
		return (
			<div>
				<Query query={userQuery} variables={{ uid: this.props.localData.myUID }}>
					{({ data: { user } }) => (
						<Query query={clientQuery} variables={{ id: this.state.clientId }} pollInterval={10000}>
							{({ data: { client }, refetch }) => {
								if (client === undefined) return <Loading key="loading-view" />;

								var components = {
									AppBar: (
										<AppBar
											key={this.state.clientId === undefined ? "app-bar" : "app-bar-" + this.state.clientId}
											UserStore={user}
											id={client.id}
											text={client.name}
											dynamic={true}
											actions={{}} //ClientActins
											target="clients"
											active={client.active}
											backView={settingsSubsection.indexOf(this.props.view) > -1 ? "clients_settings" : undefined}
											backURL="clients"
											menuOptions={clientMenuOptions}
											refetch={refetch}
										/>
									),
									SideNavigation: (
										<SideNavigation
											key="side-navigation-clients"
											title={"Client Sections"}
											user={user}
											view={this.props.localData.view}
											pages={clientPages || []}
											userPermission={permissions.find(this.state.clientId, user.permissions)}
											absolute={true}
											top={"420px"}
											viewPrefix="clients_"
											urlPrefix={"clients/" + this.state.clientId + "/"}
										/>
									),
									ClientHeader: <ClientHeader key="client-header" item={client} refetch={refetch} />
								};

								return (
									<PageLayer
										user={user}
										client={client}
										refetch={refetch}
										localData={this.props.localData}
										clientId={this.state.clientId}
										components={components}
									/>
								);
							}}
						</Query>
					)}
				</Query>
			</div>
		);
	}
}

export default Client;
