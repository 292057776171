import React from "react";

// Components
import GenericTable from "../_common/GenericTable.react";

// Query
import clientTickets from "../../../../queries/client_tickets";

// Schemas
import ticketTableSchema from "../../../../schemas/tickets";

class EscalatedTickets extends React.Component {
	render() {
		return (
			<GenericTable
				components={this.props.components}
				clientId={this.props.item.id}
				title={"Escalated tickets"}
				query={clientTickets}
				where={[
					{ name: "clientId", operator: "=", value: this.props.item.id },
					{ name: "deleted", operator: "=", value: 0 },
					{ name: "escalate", operator: ">", value: 0 }
				]}
				orderBy={"requesterUpdated"}
				orderByDirection={"asc"}
				schema={ticketTableSchema}
				type={"tickets"}
				view={this.props.localData.view}
			/>
		);
	}
}

export default EscalatedTickets;
