import React from "react";

class Container extends React.Component {
	_onClick(event) {
		event.stopPropagation();
	}

	render() {
		var myWidth = this.props.menuSpace ? "calc(100% - 220px)" : "calc(100% - 20px)";

		const primaryStyle = {
			width: this.props.width || myWidth,
			maxWidth: this.props.maxWidth || "1000px",
			minWidth: this.props.minWidth || "",
			display: "inline-block",
			padding: "10px",
			verticalAlign: this.props.verticalAlign || "middle",
			marginLeft: this.props.menuSpace ? "200px" : "0px",
			marginTop: this.props.marginTop || "0px",
			marginBottom: this.props.marginBottom || "56px"
		};

		return (
			<div onClick={this._onClick} style={primaryStyle}>
				{this.props.children}
			</div>
		);
	}
}

export default Container;
