import React from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";

// Scaffolding
import OuterScaffold from "../_common/OuterScaffold.react";
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";

// Form items
import Input from "../_common/Input.react";
import Button from "../_common/Button.react";

// Headings
import H1 from "../_common/H1.react";
import H3 from "../_common/H3.react";

// Functions
import validate from "../../functions/validate";

// Sets validation
const emailValidation = [
	{
		type: "min-length",
		value: 6
	},
	{
		type: "contains",
		value: "@"
	},
	{
		type: "contains",
		value: "."
	}
];

const showQuery = gql`
	mutation show($view: String) {
		show(view: $view) @client
	}
`;

const resetQuery = gql`
	mutation reset($email: String) {
		resetPassword(email: $email) @client
	}
`;

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onClick = this._onClick.bind(this);
		this._onBack = this._onBack.bind(this);

		this.state = { email: "", loading: false, validate: false };
	}

	componentDidMount() {
		document.getElementById("email").focus();
	}

	_onClick() {
		var emailError = validate(emailValidation, this.state.email);

		if (emailError === "") {
			this.setState({ loading: true, validate: false });
			this.props.client.mutate({
				mutation: resetQuery,
				variables: { email: this.state.email }
			});
		} else this.setState({ validate: true });
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
	}

	_onBack() {
		this.props.client.mutate({
			mutation: showQuery,
			variables: {
				view: "login"
			}
		});
	}

	render() {
		const cardStyle = {
			width: "calc(100% - 16px)",
			padding: "16px",
			textAlign: "center"
		};

		const rowStyle = {
			width: "100%",
			textAlign: "right",
			margin: "16px 0px 0px 0px"
		};

		const logoStyle = {
			height: "100px",
			marginBottom: "30px"
		};

		const forgotStyle = {
			marginRight: "16px"
		};

		return (
			<OuterScaffold>
				<Container maxWidth="500px">
					<img src={"/images/logo_white_bg@2x.png"} style={logoStyle} alt="" />
					<Card style={cardStyle}>
						<H1 text="Forgot Password" textAlign="center" margin="0px" />
						<H3
							text="Need a password reset? Please enter your email below."
							textAlign="center"
							fontSize="20px"
							lineHeight="24px"
							margin="16px 0px"
						/>
						<Input
							id="email"
							label="Email"
							field="email"
							updateFn={this._updateFn}
							validate={this.state.validate}
							validation={emailValidation}
							onReturn={this._onClick}
							errors={this.props.errors}
						/>
						<div style={rowStyle}>
							<Button text="Back" style={forgotStyle} onClick={this._onBack} type="flat" />
							<Button text="RESET PASSWORD" onClick={this._onClick} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</OuterScaffold>
		);
	}
}

export default withApollo(ResetPassword);
