import React from "react";

const labelStyle = {
	width: "100%",
	padding: "8px 0px",
	fontSize: "18px",
	display: "inline-block",
	fontWeight: "400"
};

class DataTag extends React.Component {
	render() {
		const primaryStyle = {
			display: "inline-block",
			fontFamily: "Roboto",
			fontWeight: "400",
			fontSize: this.props.fontSize || "34px",
			textAlign: this.props.textAlign || "center",
			margin: this.props.margin || "16px 8px",
			width: this.props.width || ""
		};

		const valueStyle = {
			fontSize: "34px",
			color: this.props.color || "black",
			display: "inline-block"
		};

		return (
			<div style={primaryStyle}>
				<div style={valueStyle}>{this.props.value}</div>
				<div style={labelStyle}>{this.props.label || ""}</div>
			</div>
		);
	}
}

export default DataTag;
