import React from "react";

import m from "../../../functions/m";

const primaryStyle = {
	fontSize: "13px",
	borderBottom: "1px solid lightgrey",
	padding: "14px 0px",
	position: "relative",
	backgroundColor: "transparent",
	transition: "background-color .3s ease",
	display: "inline-block",
	color: "rgba(0, 0, 0, 0.87)",
	width: "100%"
};

class DataHeaderRow extends React.Component {
	render() {
		return <div style={m(primaryStyle, this)}>{this.props.children}</div>;
	}
}

export default DataHeaderRow;
