export default async (_, props, context, info) => {
	console.log("UPDATING PERMISSION");
	console.log(props);
	await context.client.firestore.update(
		{
			path: props.id === undefined ? "permissions" : "permissions/" + props.id,
			action: props.id === undefined ? "new" : "update",
			...props
		},
		context,
		info
	);
};
