export default async (
	client,
	{ limit = 25, where = [], orderBy = [], startAfter = null, endBefore = null },
	context,
	info
) => {
	return await context.client.firestore.query(
		{
			path: "clients/" + client.id + "/tickets",
			where,
			orderBy,
			limit,
			startAfter,
			endBefore
		},
		"tickets",
		context,
		info,
		"Ticket",
		"Client",
		client.id
	);
};
