import gql from "graphql-tag";

export default gql`
	query getClientFlags($cursor: String, $where: Array, $orderBy: String, $orderByDirection: String, $pageSize: Int) {
		clientFlags(
			cursor: $cursor
			where: $where
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			pageSize: $pageSize
		) {
			cursor {
				total
				nextCursor
				previousCursor
			}
			flags {
				id
				ticketId
				reason
				text
				date
				userReviewed
				user {
					id
					name
					picture
				}
				flagger {
					id
					name
					picture
				}
			}
		}
	}
`;
