module.exports = {
	columns: [
		{
			text: "",
			size: "xs",
			value: "picture",
			type: "picture",
			width: "50px",
			center: true
		},
		{
			text: "Name",
			size: "s",
			value: "name",
			filter: true
		},
		{
			text: "Active",
			size: "xs",
			value: "active",
			type: "boolean",
			filter: true,
			center: true,
			wrap: true
		},
		{
			text: "In triage",
			size: "s",
			width: "100px",
			value: "queueCount",
			center: true,
			filter: true
		},
		{
			text: "In workflows",
			size: "xs",
			width: "100px",
			value: "workflowQueueCount",
			center: true,
			filter: true
		},
		{
			text: "Escalated",
			size: "s",
			width: "100px",
			value: "escalateCount",
			center: true,
			filter: true
		},
		{
			text: "CSAT check",
			size: "s",
			width: "100px",
			value: "csatCheckCount",
			center: true,
			filter: true
		},
		{
			text: "SLA expiring",
			size: "s",
			width: "100px",
			value: "slaCount",
			center: true,
			filter: true
		}
	],
	href: "#clients/{{row.id}}"
};
