import React from "react";

class OuterScaffold extends React.Component {
	render() {
		const minHeight = this.props.spaceForHeader ? "calc(100vh - 66px)" : "100vh";
		const primaryStyle = {
			minWidth: "100%",
			minHeight: this.props.minHeight || minHeight,
			display: "inline-block",
			textAlign: this.props.align || "center",
			paddingTop: this.props.spaceForHeader ? "66px" : "0px"
		};

		const vertAlignStyle = {
			verticalAlign: "middle",
			display: "inline-block",
			width: "0px",
			height: this.props.verticalHeight || "90vh"
		};

		return (
			<div style={primaryStyle}>
				{this.props.verticalAlign === false ? "" : <div style={vertAlignStyle} />}
				{this.props.children}
			</div>
		);
	}
}

export default OuterScaffold;
