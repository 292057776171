export default async (_, props, context, info) => {
	console.log("HITS WORKFLOW TEMPLATES RESOLVER");
	return await context.client.firestore.query(
		{
			path: "workflows",
			...props
		},
		"workflowTemplates",
		context,
		info,
		"Workflow",
		"ROOT"
	);
};
