import React from "react";

import m from "../../../functions/m";

const previewStyle = {
	position: "absolute",
	top: "calc(100% + 16px)",
	left: "100px",
	width: "400px",
	backgroundColor: "white",
	zIndex: "10",
	padding: "16px",
	boxShadow:
		"0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
	borderRadius: "2px",
	overflowX: "hidden"
};

class DataRow extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
		this.setState({ hover: true });
	}

	_onMouseOut() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
		this.setState({ hover: false });
	}

	render() {
		const color = this.props.color || "transparent";

		const primaryStyle = {
			fontSize: "13px",
			borderBottom: !this.props.last ? "1px solid lightgrey" : "",
			padding: "14px 0px",
			position: "relative",
			backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : color,
			transition: "background-color .3s ease",
			cursor: "pointer",
			display: "inline-block",
			width: "100%",
			color: "inherit"
		};

		return (
			<a
				id={this.props.id}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
				style={m(primaryStyle, this)}
				href={this.props.href || undefined}
				target={this.props.newTab ? "_blank" : ""}
				onClick={this.props.onClick ? () => this.props.onClick(this.props.item) : undefined}
			>
				{this.props.children}
				{this.state.hover && [undefined, null, ""].indexOf(this.props.preview) === -1 ? (
					<div style={previewStyle} dangerouslySetInnerHTML={{ __html: this.props.preview }} />
				) : (
					""
				)}
			</a>
		);
	}
}

export default DataRow;
