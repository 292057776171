import React from "react";
import { withApollo } from "react-apollo";

// Scaffolding
import CardTag from "../../../_common/CardTag.react";
import SectionCard from "../../../_common/SectionCard.react";
import DataTag from "../../../_common/DataTag.react";
import Container from "../../../_common/Container.react";
import OuterScaffold from "../../../_common/OuterScaffold.react";

// Form items
import Input from "../../../_common/Input.react";
import InputMultiline from "../../../_common/InputMultiline.react";

// Functions
import * as time from "../../../../functions/time";
import * as gqlHelpers from "../../../../functions/graphql_helpers";

// Queries
import upsertClient from "../../../../queries/upsert_client";

const ticketSummaryRow = {
	width: "100%",
	display: "inline-block",
	margin: "32px 0px 0px",
	textAlign: "left",
	borderTop: "1px solid lightgrey",
	paddingTop: "32px"
};

const inputStyle = {
	maxWidth: "800px"
};

class ClientDetails extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);

		var myState = {
			scroll: 0
		};
		this.state = myState;
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
	}

	async _onBlur(field, location, value) {
		this.setState({ [field]: value, validate: false });
		await gqlHelpers.mutate(
			this.props.client,
			upsertClient,
			{ id: this.props.item.id, update: { [field]: value }, parentId: null },
			"Client"
		);

		this.props.refetch();
	}

	render() {
		return [
			this.props.components.AppBar,
			this.props.components.SideNavigation,
			this.props.components.ClientHeader,
			<OuterScaffold key="client-body" spaceForHeader={false} verticalAlign={false}>
				<Container width="1200px" maxWidth="1200px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<SectionCard title="ABOUT" subtext={"Details about " + this.props.item.name}>
						<Input
							id="website"
							label="Website"
							field="website"
							updateFn={this._updateFn}
							onBlur={this._onBlur}
							value={[null, undefined].indexOf(this.state.website) > -1 ? this.props.item.website : this.state.website}
							style={inputStyle}
						/>
						<InputMultiline
							id="description"
							label="Description"
							field="description"
							onBlur={this._onBlur}
							value={this.state.description === undefined ? this.props.item.description : this.state.description}
							style={inputStyle}
						/>
						<CardTag
							text={
								"A client since " + time.parse_time(this.props.item.created || new Date(), true).toLocaleDateString()
							}
							color="orange"
						/>
						<div style={ticketSummaryRow}>
							<DataTag label="Queued tickets" value={this.props.item.queueCount} color="teal" width="160px" />
							<DataTag label="Escalated tickets" value={this.props.item.escalateCount} color="purple" width="160px" />
							<DataTag label="SLA expiring" value={this.props.item.slaCount} color="#59BBE0" width="160px" />
							<DataTag
								label="Queued workflows"
								value={this.props.item.workflowQueueCount}
								color="orange"
								width="160px"
							/>
							<DataTag
								label="CSAT Awaiting Review"
								value={this.props.item.csatCheckCount}
								color="indianred"
								width="180px"
							/>
						</div>
					</SectionCard>
				</Container>
			</OuterScaffold>
		];
	}
}
export default withApollo(ClientDetails);
