export default async (parent, props, context, info) => {
	console.log("GOING TO TRIGGER GURU SETUP: " + parent.id);
	return await context.client.firestore.query(
		{
			path: "permissions",
			where: [["clientId", "==", parent.id]],
			limit: 1000
		},
		"gurus",
		context,
		info,
		"Permission",
		"Client",
		parent.id
	);
};
