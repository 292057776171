import React from 'react';


class CardTag extends React.Component {
    render (){
        const primaryStyle = {
            display: "inline-block",
            width: this.props.width || "auto",
            maxWidth: "300px",
            position: "absolute",
            right: "0px",
            top: "20px",
            fontFamily: "Roboto",
            fontWeight: "400",
            fontSize: this.props.fontSize || "24px",
            textAlign: this.props.textAlign || "right",
            padding: "16px",
            color: "white",
            backgroundColor: this.props.color,
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 2px 0px",
            borderBottomLeftRadius: "4px",
            borderTopLeftRadius: "4px"
        };

        return (
            <h1 style={primaryStyle}>{this.props.text}</h1>
        )
    }
}

export default CardTag;