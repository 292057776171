module.exports = {
	columns: [
		{
			text: "Name",
			size: "xl",
			value: "name",
			filter: true
		},
		{
			text: "Active",
			size: "s",
			value: "active",
			type: "boolean",
			filter: true,
			center: true,
			wrap: true
		},
		{
			text: "Created",
			size: "m",
			width: "150px",
			value: "created",
			type: "date",
			filter: true
		},
		{
			text: "Updated",
			size: "m",
			width: "150px",
			value: "updated",
			type: "date",
			filter: true
		}
	],
	href: "#clients/{{clientId}}/macros/{{row.id}}"
};
