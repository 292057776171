import config from "../../config.public";
import gql from "graphql-tag";

// Helper functions
import * as permissions from "../../functions/permissions";
import updateUrl from "../../functions/update_url";

// Queries
import userQuery from "../../queries/me";

// Handles navigation of browser
const baseQuery = gql`
	query getUser {
		myUID
		loggedIn
		routeHistory
	}
`;

export default async (_, data, context) => {
	var url = data !== null ? data.url : null;

	if ([null, undefined].indexOf(url) === -1) updateUrl(url);

	var { myUID, loggedIn, routeHistory } = await context.cache.readQuery({ query: baseQuery });

	if (!loggedIn) {
		context.client.writeData({ data: { view: "login" } });
		return;
	}

	var { user } = await context.cache.readQuery({ query: userQuery, variables: { uid: myUID } });

	// Handles history
	if (routeHistory.length < 2) routeHistory.push(document.location.href);
	else if (routeHistory[routeHistory.length - 2] !== document.location.href) routeHistory.push(document.location.href);
	else routeHistory.splice(routeHistory.length - 1, 1);

	context.client.writeData({ data: { routeHistory } });

	const myRoute = document.location.hash.replace("#", "").split("/");

	// Checks if current view needs to be saved
	handle_current();

	if (myRoute.length > 0 && myRoute[0] !== "") {
		// Handles first level routes
		if (myRoute[0] === "home") run_defaults(context, user);
		else cycle_route(context, myRoute, user, config.pages, "");
	} else run_defaults(context, user);
};

var handle_current = () => {
	/*
    const myRoute = document.location.hash.replace("#", "").split("/");

	if (UI.view.indexOf("client") > -1 && myRoute.length < 2) ClientActions.close();
	else if (UI.view === "workflow details") WorkflowActions.close();
	else if (UI.view === "macro details") MacroActions.close();
	else if (UI.view.indexOf("ticket") > -1) TicketActions.close();
	else if (UI.view === "agent details") AgentActions.close();
    else if (UI.view === "activetickets") TicketActions.unsubscriveActive();
    */
};

var check_ticket_path = User => {
	const myRoute = document.location.hash.replace("#", "").split("/");

	if (myRoute.length !== 4) return false;
	if (myRoute[0] !== "clients" || myRoute[2] !== "tickets") return false;

	var myPermission = permissions.find(myRoute[1], User.permissions);
	if (User.role !== "admin" && myPermission === null) return false;

	return true;
};

var cycle_route = (context, myRoute, User, pages, view) => {
	// Finds page
	var target = myRoute.splice(0, 1)[0];
	var myPage, i;

	for (i = 0; i < pages.length; i++) {
		if (pages[i].value === target) {
			myPage = pages[i];
			view = view === "" ? myPage.value : view + "_" + myPage.value;
			break;
		}
	}

	if ((myPage === undefined || !permissions.full_check(User, view, myPage)) && !check_ticket_path(User)) {
		console.log("permissions failed");
		run_defaults(context, User);
		return;
	}

	if (myPage.pagesRequireID && myRoute.length > 0) {
		var targetID = myRoute.splice(0, 1)[0];
		var myPermission = ["clients"].indexOf(view) > -1 ? permissions.find(targetID, User.permissions) : {};

		if (User.role !== "admin" && myPermission === null) {
			run_defaults(context, User);
			return;
		}

		if (
			view === "clients" &&
			myRoute.length > 0 &&
			!permissions.check_client(User, view + "_" + myRoute[0], targetID) &&
			!check_ticket_path(User)
		) {
			run_defaults(context, User);
			return;
		} else if (view === "clients" && myRoute.length === 0 && myPage.pages !== undefined) {
			for (i = 0; i < myPage.pages.length; i++) {
				if (User.role === "admin" || permissions.check(myPermission || {}, view + "_" + myPage.pages[i].value)) {
					myRoute.push(myPage.pages[i].value);
					break;
				} else if (i === myPage.pages - 1) {
					run_defaults(context, User);
					return;
				}
			}
		}

		if (myRoute.length === 0) {
			view = view + "_details";
		}
	}

	if (myRoute.length === 0) {
		context.client.writeData({ data: { view } });
		return;
	}

	cycle_route(context, myRoute, User, myPage.pages, view);
};

var run_defaults = async (context, user) => {
	var companyHome = user.settings.filter(item => item.name === "companyHome");
	if (companyHome.length > 0 && companyHome[0].value) {
		// Runs defaults
		context.client.writeData({ data: { view: "home_manager" } });
	} else {
		// Runs defaults
		context.client.writeData({ data: { view: "home_guru" } });
	}
};
