import gql from "graphql-tag";

export default gql`
	query getClientEvents($cursor: String, $where: Array, $orderBy: String, $orderByDirection: String, $pageSize: Int) {
		clientEvents(
			cursor: $cursor
			where: $where
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			pageSize: $pageSize
		) {
			cursor {
				total
				nextCursor
				previousCursor
			}
			events {
				id
				ticketId
				action
				date
				duration
				status
				user {
					id
					name
					picture
				}
			}
		}
	}
`;
