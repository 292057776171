import gql from "graphql-tag";

export default gql`
	query searchTags($term: String, $clientId: ID!) {
		searchTags(term: $term, clientId: $clientId) {
			id
			text
		}
	}
`;
