import guid from "../../functions/guid";
import gql from "graphql-tag";

const query = gql`
	query getMessages {
		messages @client {
			id
			text
			type
		}
	}
`;

export default async (_, { text, type }, context) => {
	var data = context.cache.readQuery({ query });
	data.messages.unshift({ id: guid(), type, text, __typename: "Message" });
	return await context.client.writeQuery({ query, data });
};
