module.exports = {
	columns: [
		{
			text: "Ticket ID",
			size: "s",
			value: "ticketId",
			width: "90px"
		},
		{
			text: "User",
			size: "xs",
			value: "user",
			type: "viewer",
			wrap: true
		},
		{
			text: "Status",
			size: "s",
			value: "status",
			type: "status"
		},
		{
			text: "Event",
			size: "m",
			value: "action"
		},
		{
			text: "Date",
			size: "m",
			width: "150px",
			value: "date",
			type: "date",
			filter: true
		},
		{
			text: "Duration",
			size: "s",
			value: "duration",
			type: "duration"
		}
	],
	href: "#clients/{{clientId}}/tickets/{{row.ticketId}}"
};
