import gql from "graphql-tag";

// Queryes
import userQuery from "../../queries/me";

export default async (context, user) => {
	// Set up watcher
	var initialDone = false;
	var userWatcher = context.client.watchQuery({
		query: userQuery,
		variables: { uid: user.uid },
		pollInterval: 10000
	});

	userWatcher.subscribe(result => {
		console.log("HITS USER WATCHER!!!!");
		if (initialDone === false) {
			initialDone = true;

			if (result !== null && result.data.user !== undefined) {
				var me = result.data.user;

				context.client.writeData({ data: { myId: me.id || null, myUID: me.uid, loggedIn: true } });
				context.client.mutate({
					mutation: gql`
						mutation activate {
							monitor @client
							route @client
						}
					`
				});
			}
		}
	});
};
