import React from "react";

// Pages
import GenericTable from "../_common/GenericTable.react";
import Single from "./single";

// Query
import clientTickets from "../../../../queries/client_tickets";

// Schemas
import ticketTableSchema from "../../../../schemas/tickets";

class PageLayer extends React.Component {
	render() {
		var view = this.props.localData.view.split("_");

		switch (view[2]) {
			case "details":
				return <Single localData={this.props.localData} user={this.props.user} />;
			default:
				return (
					<GenericTable
						components={this.props.components}
						clientId={this.props.item.id}
						title={"All tickets"}
						query={clientTickets}
						where={[
							{ name: "clientId", operator: "=", value: this.props.item.id },
							{ name: "deleted", operator: "=", value: 0 }
						]}
						orderBy={"requesterUpdated"}
						orderByDirection={"desc"}
						schema={ticketTableSchema}
						type={"tickets"}
						view={this.props.localData.view}
					/>
				);
		}
	}
}

class TicketsPage extends React.Component {
	render() {
		return (
			<PageLayer
				key={"tickets-page-" + this.props.item.id}
				item={this.props.item}
				components={this.props.components}
				localData={this.props.localData}
				user={this.props.user}
			/>
		);
	}
}

export default TicketsPage;
