import React from "react";

const escalateInternalStyle = {
	borderRadius: "50%",
	backgroundColor: "indianred",
	color: "white",
	fontSize: "8px",
	lineHeight: "10px",
	padding: "3px 0px",
	width: "16px",
	marginRight: "8px",
	display: "inline-block",
	textAlign: "center"
};

const escalateExternalStyle = {
	borderRadius: "50%",
	backgroundColor: "purple",
	color: "white",
	fontSize: "8px",
	lineHeight: "10px",
	padding: "3px 0px",
	width: "16px",
	marginRight: "8px",
	display: "inline-block",
	textAlign: "center"
};

const workflowsQueuedStyle = {
	borderRadius: "50%",
	backgroundColor: "orange",
	color: "white",
	fontSize: "8px",
	lineHeight: "10px",
	padding: "3px 0px",
	width: "16px",
	marginRight: "8px",
	display: "inline-block",
	textAlign: "center"
};

const workflowsCompleteStyle = {
	borderRadius: "50%",
	backgroundColor: "yellowgreen",
	color: "white",
	fontSize: "8px",
	lineHeight: "10px",
	padding: "3px 0px",
	width: "16px",
	marginRight: "8px",
	display: "inline-block",
	textAlign: "center"
};

class RowTag extends React.Component {
	render() {
		if (this.props.type === "escalate" && this.props.value === "internal")
			return <div style={escalateInternalStyle}>I</div>;
		else if (this.props.type === "escalate" && this.props.value === "external")
			return <div style={escalateExternalStyle}>E</div>;
		else if (this.props.type === "workflows") {
			if (this.props.value.length === 0) return "";

			var complete = true;
			for (var i = 0; i < this.props.value.length; i++) {
				if (this.props.value[i].status === "queued") {
					complete = false;
					break;
				}
			}

			return <div style={complete ? workflowsCompleteStyle : workflowsQueuedStyle}>W</div>;
		} else return "";
	}
}

export default RowTag;
