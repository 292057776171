import gql from "graphql-tag";

export default gql`
	query getUser($uid: String!) {
		user(uid: $uid) {
			id
			uid
			active
			name
			picture
			cover
			email
			role
			address
			loggedIn
			created
			updated
			settings {
				name
				value
			}
			clients {
				userId
				clientId
				permissions {
					id
					value
				}
			}
			activeSession {
				id
				start
				end
			}
		}
	}
`;
