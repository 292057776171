export default async (_, props, context, info) => {
	console.log("UPDATING CLIENT");
	console.log(props);
	await context.client.firestore.update(
		{
			path: props.id === undefined ? "clients" : "clients/" + (props.id || ""),
			action: props.id === undefined ? "new" : "update",
			...props
		},
		context,
		info
	);
};
