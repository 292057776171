import gql from "graphql-tag";

export default gql`
	query searchUsers($term: String) {
		searchUsers(term: $term) {
			id
			name
			email
			active
			picture
			role
		}
	}
`;
