function m (...args) {
    var res = {};
    for (var i = 0; i < args.length; i++){
        if (args[i]) {
            Object.assign(res, args[i]);
        }
    }
    return res;
}

function harmonize (primaryStyle, obj, field){
    if (field === undefined)
        return obj.props.style === undefined ? primaryStyle : m(primaryStyle, obj.props.style)
    else
        return obj.props.style === undefined ? primaryStyle : m(primaryStyle, obj.props[field])
}

export default harmonize;