import React from "react";

const imageStyle = {
	height: "30px",
	width: "30px",
	display: "inline-block",
	verticalAlign: "middle",
	marginRight: "16px",
	borderRadius: "50%",
	cursor: "pointer",
	fontWeight: "900",
	fontFamily: "Roboto",
	fontSize: "36px",
	textAlign: "center"
};

const imageTextStyle = {
	fontSize: "16px",
	height: "16px",
	width: "30px",
	textAlign: "center",
	padding: "7px 0px",
	backgroundColor: "rgba(0, 0, 0, 0.62)",
	color: "white",
	cursor: "pointer",
	borderRadius: "50%",
	verticalAlign: "middle",
	display: "inline-block",
	lineHeight: "16px",
	marginRight: "16px"
};

class PictureBadge extends React.Component {
	render() {
		const name = this.props.item.name || this.props.item.email || "";

		if ([null, undefined, ""].indexOf(this.props.item.picture) === -1)
			return <img style={imageStyle} src={this.props.item.picture} alt={name.toUpperCase().substr(0, 1)} />;
		else return <div style={imageTextStyle}>{name.toUpperCase().substr(0, 1)}</div>;
	}
}

export default PictureBadge;
