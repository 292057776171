import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import firebase from "firebase/app";
import "firebase/auth";

import config from "../config.public";
import resolvers from "./resolvers";
import defaults from "./defaults";

import typeDefs from "./schema";

export default () => {
	if (client === undefined) setup();
	return client;
};

var get_url = () => {
	var hostname = document.location.hostname;

	if (hostname.indexOf("localhost") > -1) return "http://localhost:5000/" + config.gcf.prod.projectId + "/us-central1/";
	else return "https://" + hostname.replace("app.", "api.") + "/";
};

var client;

const httpLink = createHttpLink({
	uri: get_url() + "apollo/graphql"
});

const authLink = setContext(async (_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = await firebase
		.auth()
		.currentUser.getIdToken()
		.then(token => token);
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			Authorization: token ? `Bearer ${token}` : ""
		}
	};
});

var setup = () => {
	const cache = new InMemoryCache({ addTypename: true });
	client = new ApolloClient({
		cache: cache, //{ addTypename: false }
		link: authLink.concat(httpLink),
		/*
		link: new HttpLink({
			uri: get_url() + "apollo/graphql"
        }),
        */
		resolvers,
		typeDefs
	});

	cache.writeData({ data: defaults });
	client.onResetStore(() => cache.writeData({ data: defaults }));
};
