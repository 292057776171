export default async (_, props, context, info) => {
	console.log("HITS CLIENTS RESOLVER");
	return await context.client.firestore.query(
		{
			path: "clients",
			...props
		},
		"clients",
		context,
		info,
		"Client",
		"ROOT"
	);
};
