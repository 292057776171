import React from "react";
import RowTag from "../components/_common/datatable/RowTag.react";
import Viewer from "../components/_common/Viewer.react";
import PictureBadge from "../components/_common/datatable/PictureBadge.react";

// Functions
import * as time from "./time";

const booleanTrueStyle = {
	color: "rgb(76, 167, 224)",
	position: "absolute",
	top: "-14px",
	left: "calc(50% - 12px)"
};

const booleanFalseStyle = {
	color: "grey",
	position: "absolute",
	top: "-14px",
	left: "calc(50% - 12px)"
};

var get_value = (schema, row) => {
	var schemaValue = !Array.isArray(schema.value) ? [schema.value] : schema.value;

	var i;
	if (schema.type === "tags") {
		return schemaValue.map(item => {
			var value = row[item];
			if ([undefined, null, ""].indexOf(value) > -1) return "";
			return <RowTag key={"rowtag-" + row.id + "-" + item} type={item} value={value} />;
		});
	}

	for (i = 0; i < schemaValue.length; i++)
		if ([undefined, null, ""].indexOf(row[schemaValue[i]]) === -1) return row[schemaValue[i]];

	return "";
};

var get_cell_value = (schema, row) => {
	var value = get_value(schema, row);

	if ([undefined, null, ""].indexOf(value) > -1 && schema.type !== "picture") return "";
	else if (schema.type === "date") return new Date(value).toLocaleString();
	else if (schema.type === "status") return value.toUpperCase();
	else if (schema.type === "sla") {
		const now = new Date();
		var slaTime = Math.round((Number(new Date(value)) - now) / 60000);
		if (Math.abs(slaTime) < 60) slaTime = String(slaTime) + "m";
		else slaTime = String(Math.ceil(slaTime / 60)) + "h";

		return slaTime;
	} else if (schema.type === "viewer") return <Viewer item={value} />;
	else if (schema.type === "duration") return time.parse_diff(value / 1000);
	else if (schema.type === "boolean") {
		if (value)
			return (
				<i className="material-icons" style={booleanTrueStyle}>
					check
				</i>
			);
		else
			return (
				<i className="material-icons" style={booleanFalseStyle}>
					not_interested
				</i>
			);
	} else if (schema.type === "picture") return <PictureBadge item={row} />;

	return value;
};

var get_cell_color = (schema, row) => {
	var value = get_value(schema, row);

	if (schema.type === "status") {
		return get_status_color(value);
	} else if (schema.type === "sla") {
		const now = new Date();
		var slaTime = Math.round((Number(new Date(value)) - now) / 60000);
		if (slaTime > 30) return "green";
		else return "indianred";
	} else return "inherit";
};

var get_status_color = value => {
	if (value === "new") return "#F5CA00";
	else if (value === "open") return "#E82A2A";
	else if (value === "pending") return "#59BBE0";
	else if (value === "hold") return "black";
	else if (value === "solved") return "#828282";
	else if (value === "closed") return "black";
	else if (value === "deleted") return "black";
	else return "black";
};
export { get_cell_color, get_cell_value, get_value, get_status_color };
