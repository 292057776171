import React from "react";

// Components
import MenuItem from "./MenuItem.react";

// Functions
import m from "../../functions/m";

class Menu extends React.Component {
	constructor(props) {
		super(props);
		this._onFocus = this._onFocus.bind(this);
		this._onClick = this._onClick.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onChange = this._onChange.bind(this);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = { focus: false, hover: false };
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ value: nextProps.value });
	}

	_onClick() {
		document.getElementById(this.props.id).focus();
	}

	_onFocus() {
		this.setState({ focus: true });
	}

	_onBlur() {
		this.setState({ focus: false });
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onChange(value) {
		this.props.updateFn(this.props.field, this.props.location, value);
		this.setState({ value: value, focus: false, hover: false });
		document.getElementById(this.props.id).blur();
	}

	render() {
		const open = this.state.focus || this.state.hover ? true : false;

		const primaryStyle = {
			display: "inline-block",
			position: "relative",
			verticalAlign: "middle",
			marginTop: "-4px"
		};

		const buttonStyle = {
			padding: "0px",
			border: "none",
			color: "rgba(0, 0, 0, 0.87)",
			backgroundColor: "transparent",
			textAlign: "left",
			cursor: "pointer",
			outline: "none"
		};

		const iconStyle = {
			fontSize: "24px",
			display: "inline-block",
			verticalAlign: "middle",
			position: "relative"
		};

		const listStyle = {
			display: open ? "inline-block" : "none",
			position: "absolute",
			top: "0px",
			right: "24px",
			zIndex: "3",
			padding: "0px",
			margin: "0px",
			fontSize: "14px",
			textAlign: "left",
			listStyle: "none",
			backgroundColor: "white",
			borderRadius: "2px",
			color: "rgba(0, 0, 0, 0.87)",
			boxShadow: "0 2px 5px 0 rgba(0,0,0,.26)",
			maxHeight: "70vh",
			overflowY: "auto",
			width: this.props.menuWidth || "200px"
		};

		return (
			<div style={m(primaryStyle, this)}>
				<button
					id={this.props.id}
					style={buttonStyle}
					onFocus={this._onFocus}
					onBlur={this._onBlur}
					onClick={this._onClick}
				>
					<i style={iconStyle} className="material-icons">
						more_vert
					</i>
				</button>
				<ul style={listStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
					{this.props.items.map((item, index, arr) => (
						<MenuItem item={item} updateFn={this._onChange} key={this.props.id + "-" + index} />
					))}
				</ul>
			</div>
		);
	}
}

export default Menu;
