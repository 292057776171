import React from "react";
import config from "../../config.public";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";

class MenuItem extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.client.mutate({
			mutation: gql`
				mutation route($url: String) {
					route(url: $url) @client
				}
			`,
			variables: { url: (this.props.urlPrefix || "") + this.props.item.value }
		});
	}

	render() {
		const primaryStyle = {
			width: "calc(100% - 28px)",
			cursor: "pointer",
			lineHeight: "40px",
			padding: "0px 12px 0px 16px",
			borderRadius: "2px",
			transition: "all .3s ease",
			backgroundColor: this.state.hover || this.props.selected ? "rgba(0,0,0,.05)" : "",
			fontSize: "14px",
			fontFamily: "Roboto",
			position: "relative",
			color: this.props.selected ? this.props.item.iconColor || config.core.primaryColor : "inherit"
		};

		const iconStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "24px",
			height: "24px",
			color: this.props.selected ? "inherit" : "slategrey"
		};

		const textStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			marginLeft: "24px"
		};

		const badgeStyle = {
			borderRadius: "50%",
			backgroundColor: this.props.item.badgeColor || "orange",
			color: "white",
			textAlign: "center",
			fontSize: "14px",
			lineHeight: "14px",
			padding: "6px 0px",
			width: "26px",
			marginLeft: "8px",
			display: "inline-block"
		};

		return (
			<div style={primaryStyle} onClick={this._onClick} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
				<i style={iconStyle} className="material-icons">
					{this.props.item.icon}
				</i>
				<span style={textStyle}>{this.props.item.text}</span>
				{this.props.item.badge !== undefined ? <div style={badgeStyle}>{this.props.item.badge}</div> : ""}
			</div>
		);
	}
}

const titleStyle = {
	width: "calc(100% - 16px)",
	textAlign: "left",
	padding: "0px 0px 24px 16px",
	fontWeight: "700",
	fontSize: "24px",
	color: "rgba(0, 0, 0, 0.87)"
};

var parse_pages = obj => {
	var newPages = [];
	var userPages = obj.userPages || [];
	var j;

	for (var i = 0; i < obj.pages.length; i++) {
		if (
			obj.pages[i].roles.indexOf(obj.user.role) > -1 ||
			obj.pages[i].roles.indexOf("any") > -1 ||
			userPages.indexOf(obj.pages[i].value) > -1 ||
			(obj.userPermission !== undefined &&
				(obj.userPermission.full_access || obj.userPermission[obj.viewPrefix.replace(" ", "_") + obj.pages[i].value]))
		)
			newPages.push(obj.pages[i]);
		else if (obj.pages[i].globalPermissions !== undefined) {
			for (j = 0; j < obj.pages[i].globalPermissions.length; j++) {
				if (obj.user[obj.pages[i].globalPermissions[j]]) {
					newPages.push(obj.pages[i]);
					break;
				}
			}
		}
	}

	return newPages;
};

class SideNavigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pages: parse_pages(this.props)
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			pages: parse_pages(nextProps)
		});
	}

	render() {
		const primaryStyle = {
			position: this.props.absolute ? "absolute" : "fixed",
			left: "0px",
			top: this.props.top || "66px",
			padding: "12px 8px 52px",
			overflowY: "auto",
			overflowX: "visible",
			width: "220px"
		};

		return (
			<div style={primaryStyle}>
				{this.props.title !== undefined ? <div style={titleStyle}>{this.props.title}</div> : ""}
				{this.state.pages.map((item, index, arr) => (
					<MenuItem
						key={"side-nav-" + index}
						index={index}
						item={item}
						client={this.props.client}
						selected={this.props.view === (this.props.viewPrefix || "") + item.value}
						viewPrefix={this.props.viewPrefix}
						urlPrefix={this.props.urlPrefix}
					/>
				))}
			</div>
		);
	}
}

export default withApollo(SideNavigation);
