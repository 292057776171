import React from "react";

// Functions
import m from "../../functions/m";

class Toggle extends React.Component {
	constructor(props) {
		super(props);
		this._onChange = this._onChange.bind(this);
		this.state = { value: this.props.value === undefined ? false : this.props.value };
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.value !== nextProps.value) this.setState({ value: nextProps.value });
	}

	_onChange(event) {
		this.setState({ value: this.state.value ? false : true });
		if (this.props.updateFn !== undefined)
			this.props.updateFn(this.props.field, this.props.location, this.state.value ? false : true);
	}

	render() {
		const primaryStyle = {
			display: "inline-block",
			textAlign: "left",
			verticalAlign: "middle",
			lineHeight: "24px"
		};

		const labelStyle = {
			width: "30px",
			height: "15px",
			borderRadius: "15px",
			cursor: "pointer",
			display: "inline-block",
			verticalAlign: "middle",
			transition: "background .3s ease",
			backgroundColor: this.props.value ? "rgba(76, 167, 224, 0.5)" : "rgba(80,80,80,.7)",
			fontWeight: "400"
		};

		const checkStyle = {
			backgroundColor: this.props.value ? "rgb(76, 167, 224)" : "#F1F1F1",
			transition: "all 0.3s ease-in-out",
			boxShadow: "0 1px 3px 1px rgba(0,0,0,.4)",
			height: "20px",
			width: "20px",
			borderRadius: "20px",
			position: "relative",
			left: this.props.value ? "15px" : "-2px",
			top: "-3px",
			display: "inline-block"
		};

		// TO DO: Remove outer div once react 16 arrives
		return (
			<div style={m(primaryStyle, this)}>
				<label style={labelStyle} onClick={this._onChange}>
					<span style={checkStyle} />
				</label>
			</div>
		);
	}
}

export default Toggle;
