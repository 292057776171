module.exports = {
	columns: [
		{
			text: "SLA",
			size: "xs",
			width: "40px",
			value: ["externalSla", "sla"],
			type: "sla"
		},
		{
			text: "External #",
			size: "xs",
			value: "externalId"
		},
		{
			text: "Status",
			size: "xs",
			value: "status",
			type: "status"
		},
		{
			text: "Requester",
			size: "s",
			value: "requesterEmail"
		},
		{
			text: "Subject",
			size: "l",
			value: "subject"
		},
		{
			text: "Last Updated",
			size: "s",
			width: "150px",
			value: "requesterUpdated",
			type: "date",
			filter: true
		},
		{
			text: "",
			size: "s",
			width: "calc(100% - 920px)",
			value: ["claimed", "viewing", "escalate", "workflows"],
			type: "tags"
		}
	],
	href: "#clients/{{clientId}}/tickets/{{row.id}}",
	preview: "description"
};
