export default async (_, props, context, info) => {
	// TO DO: Do we need to write the session both places still?
	var sessionId = await context.client.firestore.update(
		{
			path: "users/" + props.userId + "/sessions",
			...props,
			action: "new"
		},
		context,
		info
	);

	if (props.update.global === undefined) {
		props.update.userId = props.userId;
		await context.client.firestore.update(
			{
				path: "activeSessions/" + sessionId,
				...props,
				action: "new"
			},
			context,
			info
		);
	}

	return { id: sessionId, __typename: "Session" };
};
