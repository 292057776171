import React from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";

class LinearLoader extends React.Component {
	constructor(props) {
		super(props);
		this._close = this._close.bind(this);
		this.state = { mounted: false };
	}

	componentDidMount() {
		var obj = this;
		setTimeout(() => obj.setState({ mounted: true }), 50);
	}

	componentWillReceiveProps(nextProps) {
		var obj = this;
		if (nextProps.status === "done" || nextProps.status === "error") setTimeout(obj._close, 500);
	}

	_close() {
		this.props.client.mutate({
			mutation: gql`
				mutation showSaver {
					saver(value: "closed") @client
				}
			`
		});
	}

	render() {
		var marginLeft = "-100%";
		if (this.props.status === "in progress" && this.state.mounted) marginLeft = "-50%";
		else if (this.props.status === "done") marginLeft = "0px";

		const outerDivStyle = {
			width: "100%",
			height: "4px",
			position: "absolute",
			bottom: this.props.top ? "calc(100% - 4px)" : "-4px",
			left: "0px",
			backgroundColor: "rgba(128, 0, 128, 0.3)"
		};

		const innerDivStyle = {
			backgroundColor: "purple",
			height: "4px",
			width: "100%",
			marginLeft: marginLeft,
			transition: this.props.status === "done" ? "all .5s ease" : "all 1s ease"
		};

		return (
			<div style={outerDivStyle}>
				<div style={innerDivStyle} />
			</div>
		);
	}
}

export default withApollo(LinearLoader);
