import React from "react";

// Components
import GenericTable from "../_common/GenericTable.react";

// Query
import clientEvents from "../../../../queries/client_events";

// Schemas
import eventTableSchema from "../../../../schemas/client_events";

class QueuedTickets extends React.Component {
	render() {
		return (
			<GenericTable
				components={this.props.components}
				key={"client-tickets-queued-" + this.props.item.id}
				clientId={this.props.item.id}
				title={"Events"}
				query={clientEvents}
				where={[{ name: "clientId", operator: "=", value: this.props.item.id }]}
				orderBy={"date"}
				orderByDirection={"desc"}
				schema={eventTableSchema}
				type={"events"}
				view={this.props.localData.view}
			/>
		);
	}
}

export default QueuedTickets;
