import gql from "graphql-tag";

// Queries
const viewQuery = gql`
	query getView {
		overlay @client
	}
`;

export default async (_, { overlay, clientId = null, ticketId = null }, context) => {
	var currentView = context.cache.readQuery({ query: viewQuery });
	if (currentView.overlay !== overlay)
		context.client.writeData({ data: { overlay: overlay || "", overlayDetails: { clientId, ticketId } } });

	return;
};
