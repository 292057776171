import firebase from "firebase/app";
import "firebase/storage";
import uuidv4 from "uuid/v4";

var storage = firebase.app().storage("gs://hioperator-prod.appspot.com/");

function upload(files, folder, type, results, master_callback) {
	if (files.length > 0) {
		var single = files.splice(0, 1);
		single = single[0];

		// Points to the root reference
		var ref = storage.ref();
		const metadata = {
			contentType: single.type,
			cacheControl: "public, max-age=31536000"
		};

		const name = uuidv4() + get_suffix(single.name);

		ref
			.child(folder + "/" + name)
			.put(single, metadata)
			.then(function(snapshot) {
				snapshot.ref.getDownloadURL().then(function(downloadURL) {
					results.push({ filename: name, url: downloadURL, contentType: single.type });
					upload(files, folder, type, results, master_callback);
				});
			});
	} else master_callback(results, type);
}

function get_suffix(url) {
	var index = url.lastIndexOf(".");
	return url.substr(index, url.length);
}

export { upload };
