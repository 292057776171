import React from "react";
import MDSpinner from "react-md-spinner";

// Functions
import m from "../../functions/m";
import * as objects from "../../functions/objects";
import * as search from "../../functions/search";

class Item extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onMouseDown = this._onMouseDown.bind(this);

		this._onClick = this._onClick.bind(this);
		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onMouseDown(event) {
		console.log("should event be prevented: " + this.props.blockEvent);
		if (this.props.blockEvent) event.preventDefault();
	}

	_onClick(event) {
		if (this.props.blockEvent) event.preventDefault();
		this.props.updateFn(this.props.item);
	}

	render() {
		const col = this.props.item.divider ? 1 : this.props.col || 1;

		const primaryStyle = {
			padding: this.props.item.divider ? "" : "16px",
			fontSize: "16px",
			fontFamily: "Roboto",
			fontWeight: "400",
			width: this.props.item.divider ? "100%" : "calc(" + 100 / col + "% - 32px)",
			backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.05)" : "transparent",
			transition: "all 0.3s ease",
			cursor: "pointer",
			borderBottom: this.props.item.divider ? "1px solid rgba(0, 0, 0, 0.54)" : "",
			wordWrap: "break-word",
			position: "relative",
			display: col > 1 ? "inline-block" : ""
		};

		const iconStyle = {
			verticalAlign: "middle",
			marginRight: "16px",
			color: this.props.item.iconColor !== undefined ? this.props.item.iconColor : "inherit",
			fontSize: "24px",
			transform: this.props.item.iconRotation !== undefined ? "rotate(" + this.props.item.iconRotation + "deg)" : ""
		};

		const arrowStyle = {
			position: "absolute",
			right: "0px",
			top: "calc((100% - 24px)/2)"
		};

		const backArrowStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			marginLeft: "-8px"
		};

		return (
			<li
				style={primaryStyle}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
				onClick={this._onClick}
				onMouseDown={this._onMouseDown}
			>
				{this.props.item.icon !== undefined ? (
					<i style={iconStyle} className="material-icons">
						{this.props.item.icon}
					</i>
				) : (
					""
				)}
				{this.props.item.back ? (
					<i style={backArrowStyle} className="material-icons">
						keyboard_arrow_left
					</i>
				) : (
					""
				)}
				{this.props.item.text || ""}
				{this.props.item.items !== undefined ? (
					<i style={arrowStyle} className="material-icons">
						keyboard_arrow_right
					</i>
				) : (
					""
				)}
			</li>
		);
	}
}

const searchInputStyle = {
	padding: "0px",
	borderRadius: "4px",
	border: "none",
	fontSize: "16px",
	lineHeight: "22px",
	color: "rgba(0, 0, 0, 0.87)",
	fontFamily: "Roboto",
	fontWeight: "400",
	backgroundColor: "transparent",
	width: "calc(100% - 24px)",
	display: "inline-block",
	outline: "none"
};

class Dropdown extends React.Component {
	constructor(props) {
		super(props);
		this._onFocus = this._onFocus.bind(this);
		this._onClick = this._onClick.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onChange = this._onChange.bind(this);
		this._onBack = this._onBack.bind(this);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onButtonMouseOver = this._onButtonMouseOver.bind(this);
		this._onButtonMouseOut = this._onButtonMouseOut.bind(this);
		this._onSearch = this._onSearch.bind(this);

		this._onLabelClick = this._onLabelClick.bind(this);
		this.buttonRef = React.createRef();
		this.inputRef = React.createRef();

		var myState = {
			focus: false,
			hover: false,
			inputOpen: false,
			buttonHover: false,
			error: "",
			items: this.props.items,
			layer: -1,
			query: "",
			value: this.props.value
		};

		this.state = myState;
	}

	componentWillReceiveProps(nextProps) {
		var myState = {
			items: nextProps.items.length !== this.props.items.length ? nextProps.items : this.state.items,
			error:
				nextProps.error && (nextProps.value === "" || nextProps.value === undefined) ? "Please choose a response" : ""
		};

		if (nextProps.value !== this.props.value) myState.value = nextProps.value;

		this.setState(myState);
	}

	componentDidUpdate() {
		if (this.state.focus && !this.state.inputOpen && this.props.searchable) {
			this.inputRef.current.focus();
			this.setState({ inputOpen: true });
		} else if (this.state.focus && !this.state.inputOpen) {
			this.buttonRef.current.focus(); //document.getElementById(this.props.id).focus();
			this.setState({ inputOpen: true });
		}
	}

	_onFocus() {
		this.setState({ focus: true });

		var elem = this.obj.getElementsByTagName("ul")[0];

		setTimeout(function() {
			var ancestor = elem.closest(".scrollable");
			if (ancestor !== null) {
				if (elem.getBoundingClientRect().bottom > ancestor.getBoundingClientRect().bottom) {
					ancestor.scrollTop =
						ancestor.scrollTop + elem.getBoundingClientRect().bottom - ancestor.getBoundingClientRect().bottom + 100;
				}
			}
		}, 100);
	}

	_onClick() {
		this.buttonRef.current.focus(); //document.getElementById(this.props.id).focus();
	}

	_onBlur() {
		this.setState({ focus: false, inputOpen: false });
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onButtonMouseOver() {
		this.setState({ buttonHover: true });
	}

	_onButtonMouseOut() {
		this.setState({ buttonHover: false });
	}

	_onChange(obj) {
		if (obj.items === undefined) {
			this.setState({ value: obj.value, focus: false, hover: false, inputOpen: false, error: "", query: "" });
			this.props.updateFn(this.props.field, this.props.location, obj.value);
			document.getElementById(this.props.id).blur();
		} else {
			document.getElementById(this.props.id).focus();
			this.setState({
				query: "",
				items: obj.items,
				layer: obj.layer,
				section: obj.section
			});
		}
	}

	_onBack(obj) {
		var result = objects.find_deep(this.props.items, null, this.state.section[this.state.section.length - 1]);
		var section = this.state.section.concat();
		section.splice(section.length - 1, 1);

		if (result !== null && result.parent !== undefined)
			this.setState({
				items: Array.isArray(result.parent) ? result.parent : result.parent.items,
				layer: this.state.layer - 1,
				section: section
			});
		document.getElementById(this.props.id).focus();
	}

	_onLabelClick() {
		this._onFocus();
	}

	_onSearch(event) {
		this.setState({ query: event.currentTarget.value });
	}

	render() {
		const open = this.state.focus || this.state.hover ? true : false;
		const loading = this.props.loading || false;

		const primaryStyle = {
			width: "100%",
			display: "inline-block",
			fontSize: "16px",
			//height: this.props.simple ? "20px" : "56px",
			marginBottom: this.props.simple ? "0px" : "13px",
			position: "relative",
			backgroundColor: this.props.colored ? "rgba(0, 0, 0, 0.06)" : "transparent"
		};

		var labelColor = "rgba(0, 0, 0, 0.54)";
		if (this.state.error !== "") labelColor = "red";
		else if (this.state.focus || this.state.value) labelColor = "rgba(0, 0, 0, 0.38)";

		const labelStyle = {
			fontSize: this.state.value !== "" || (open && this.props.searchable) ? "12px" : "16px",
			color: labelColor,
			position: "absolute",
			transition: "0.2s all ease",
			left: "16px",
			top: this.state.value !== "" || (open && this.props.searchable) ? "8px" : "20px",
			cursor: "pointer",
			fontWeight: "400"
		};

		const inputRegularStyle = {
			padding: this.props.thin ? "8px 0px 8px 16px" : "28px 0px 8px 16px",
			borderRadius: "4px",
			border: "none",
			fontSize: "inherit",
			lineHeight: "18px",
			color: this.props.dark ? "rgba(255, 255, 255, 0.87)" : "rgba(0, 0, 0, 0.87)",
			fontFamily: "Roboto",
			fontWeight: "400",
			//minHeight: this.props.simple ? "" : "56px",
			width: "100%",
			backgroundImage: this.props.simple
				? ""
				: "linear-gradient(rgb(76, 167, 224),rgb(76, 167, 224)),linear-gradient(#D2D2D2,#D2D2D2)",
			textAlign: "left",
			cursor: "pointer"
		};

		const backgroundColor = this.props.backgroundColor || "rgba(0, 0, 0, 0.62)";

		const inputButtonStyle = {
			display: "inline-block",
			padding: this.props.mini ? "4px" : "11px 16px",
			verticalAlign: "middle",
			borderRadius: this.props.mini ? "50%" : "2px",
			fontSize: "14px",
			cursor: "pointer",
			width: this.props.mini ? "37px" : "100%",
			backgroundColor: this.state.buttonHover || loading ? "transparent" : backgroundColor,
			color: this.state.buttonHover || loading ? "rgba(0, 0, 0, 0.87)" : "white",
			border: this.state.buttonHover || loading ? "1px solid black" : "1px solid transparent",
			transition: ".2s all ease",
			position: "relative",
			boxShadow:
				"0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
			textAlign: "center",
			fontWeight: "400",
			fontFamily: "Roboto",
			outline: "none"
		};

		const inputMiniStyle = {
			display: "inline-block",
			padding: "4px",
			verticalAlign: "middle",
			borderRadius: "50%",
			fontSize: "14px",
			cursor: "pointer",
			width: "37px",
			border: "none",
			backgroundColor: this.state.buttonHover ? backgroundColor : "transparent",
			color: this.state.buttonHover ? "white" : "rgba(0, 0, 0, 0.87)",
			transition: ".2s all ease",
			position: "relative",
			boxShadow: this.state.buttonHover
				? "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
				: "",
			textAlign: "center",
			fontWeight: "400",
			fontFamily: "Roboto",
			outline: "none"
		};

		const textStyle = {
			width: "calc(100% - 32px)",
			display: "inline-block",
			verticalAlign: "middle",
			paddingRight: "8px",
			minHeight: "18px"
		};

		const iconStyle = {
			width: "20px",
			display: "inline-block",
			verticalAlign: "middle",
			position: "relative",
			top: this.props.button || this.props.simple ? "" : "-10px"
		};

		const col = this.props.columns || 1;
		const listStyle = {
			display: open ? "inline-block" : "none",
			position: "absolute",
			top: "calc(100% + 10px)",
			left: this.props.mini ? "" : "0px",
			right: this.props.mini ? "0px" : "",
			zIndex: "4",
			padding: "0px",
			margin: "0px",
			fontSize: "14px",
			textAlign: "left",
			listStyle: "none",
			backgroundColor: "white",
			borderRadius: "2px",
			color: "rgba(0, 0, 0, 0.87)",
			boxShadow: "0 2px 5px 0 rgba(0,0,0,.26)",
			maxHeight: this.props.maxListHeight || "40vh",
			overflowY: "auto",
			width: col + "00%"
		};

		const underStyle = {
			fontSize: "12px",
			marginTop: "8px",
			marginLeft: "16px",
			color: this.state.error === "" ? "rgba(0, 0, 0, 0.54)" : "red",
			fontWeight: "400",
			transition: "0.2s all ease",
			opacity: this.state.error === "" && this.props.helpText === undefined ? "0" : "1"
		};

		var textValue = "";
		if (this.state.value !== undefined && this.state.value !== "") {
			var selected = objects.find_deep(this.props.items, null, this.state.value, "value");
			if (selected !== null && selected.obj !== null) textValue = selected.obj.text;
		}

		// Sets text if its predefined
		if (this.props.text !== undefined && this.props.button) textValue = this.props.text;

		var buttonStyle = inputRegularStyle;
		if (this.props.mini) buttonStyle = inputMiniStyle;
		else if (this.props.button) buttonStyle = inputButtonStyle;

		// Sets items
		const items =
			this.props.searchable && open
				? search.find_items(this.state.query, this.state.items, ["text", "value"])
				: this.state.items;

		return (
			<div
				ref={input => {
					this.obj = input;
				}}
				style={m(primaryStyle, this)}
			>
				{this.props.label && !this.props.simple ? (
					<span style={labelStyle} onClick={this._onLabelClick}>
						{this.props.label}
					</span>
				) : (
					""
				)}
				<button
					id={this.props.id}
					style={buttonStyle}
					className={this.props.button ? "" : "mui-underline"}
					onFocus={this._onFocus}
					onBlur={this._onBlur}
					onMouseOver={this._onButtonMouseOver}
					onMouseOut={this._onButtonMouseOut}
					onClick={this.props.onClick || this._onClick}
					ref={this.buttonRef}
				>
					{this.props.searchable && open ? (
						<input
							style={searchInputStyle}
							onFocus={this._onFocus}
							onBlur={this._onBlur}
							ref={this.inputRef}
							value={this.state.query}
							onChange={this._onSearch}
						/>
					) : (
						<div style={textStyle}>
							{loading ? <MDSpinner size={14} singleColor="rgba(0, 0, 0, 0.62)" /> : textValue}
						</div>
					)}
					<i style={iconStyle} className="material-icons">
						{this.state.focus ? "arrow_drop_up" : "arrow_drop_down"}
					</i>
				</button>
				{!this.props.noHelp && !this.props.thin ? (
					<div style={underStyle}>
						{this.state.error === "" && this.props.helpText ? this.props.helpText : this.state.error}
					</div>
				) : (
					""
				)}
				<ul style={listStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
					{this.state.layer !== -1 ? (
						<Item
							item={{ value: "back", text: "Back", back: true }}
							key={this.props.id + "-back"}
							updateFn={this._onBack}
						/>
					) : (
						""
					)}
					{items.map((item, index, arr) => (
						<Item
							item={item}
							updateFn={this._onChange}
							key={this.props.id + "-" + index}
							blockEvent={this.props.blockEvent}
							col={col}
						/>
					))}
				</ul>
			</div>
		);
	}
}

export default Dropdown;
