var pure_text = text => {
	if (typeof text !== "string") return text;

	var el = document.createElement("div");
	el.innerHTML = text;
	return el.innerText;
};

var strip_outer = text => {
	if (typeof text !== "string") return text;

	if (text.indexOf("<p>") === 0) text = text.substr(3, text.length);
	if (text.lastIndexOf("</p>") === text.length - 4 && text.lastIndexOf("</p>") > 0)
		text = text.substr(0, text.length - 4);

	if (text.indexOf("<div>") === 0) text = text.substr(5, text.length);
	if (text.lastIndexOf("</div>") === text.length - 6 && text.lastIndexOf("</div>") > 0)
		text = text.substr(0, text.length - 6);

	return text;
};

var add_outer = text => {
	if (text.indexOf("<p>") !== 0) text = "<p>" + text;
	if (text.lastIndexOf("</p>") !== text.length - 4) text = text + "</";

	return text;
};

var fix_bad_html = text => {
	if (typeof text !== "string") return text;

	text = text.replace(/\n/g, "<br/>").replace(/<br>/g, "<br/>");
	text = text.replace(/<p><br\/><\/p>/g, "<div></div>");
	text = text.replace(/<div><br\/><\/div>/g, "<div></div>");

	text = text.replace(/<p>/g, "<div>");
	text = text.replace(/<\/p>/g, "</div>");

	return text;
};

var correct_for_submit = text => {
	if (typeof text !== "string") return text;

	text = text.replace(/<div><\/div>/g, "<div><br/></div>");

	return text;
};

export { pure_text, strip_outer, add_outer, fix_bad_html, correct_for_submit };
