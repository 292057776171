export default {
	myId: null,
	myUID: null,
	view: "loading",
	viewData: null,
	messages: [],
	routeHistory: [],
	loggedIn: false,
	overlay: "",
	overlayDetails: [],
	saver: "closed"
};
