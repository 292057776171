export default async (parent, props, context, info) => {
	console.log("FIRING GET USER FOR PERMISSION: " + parent.userId);
	return await context.client.firestore.query(
		{
			path: "users/" + parent.userId
		},
		"user",
		context,
		info,
		"User",
		"Permission",
		parent.id
	);
};
