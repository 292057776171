import _ from "lodash";
import clone from "./clone";

function order(obj, sortBy, property) {
	var isDate = false;
	var isText = false;
	var direction = "asc";
	if (sortBy.indexOf("newest") > -1) {
		isDate = true;
		direction = "desc";
		sortBy = sortBy.replace(" newest", "");
	} else if (sortBy.indexOf("oldest") > -1) {
		isDate = true;
		direction = "asc";
		sortBy = sortBy.replace(" oldest", "");
	} else if (sortBy.indexOf("longest") > -1) {
		direction = "desc";
		sortBy = sortBy.replace(" longest", "");
	} else if (sortBy.indexOf("shortest") > -1) {
		direction = "asc";
		sortBy = sortBy.replace(" shortest", "");
	} else if (sortBy.indexOf("biggest") > -1) {
		direction = "desc";
		sortBy = sortBy.replace(" biggest", "");
	} else if (sortBy.indexOf("smallest") > -1) {
		direction = "asc";
		sortBy = sortBy.replace(" smallest", "");
	} else if (sortBy.indexOf("asc") > -1) {
		direction = "asc";
		isText = true;
		sortBy = sortBy.replace(" asc", "");
	} else if (sortBy.indexOf("desc") > -1) {
		direction = "desc";
		isText = true;
		sortBy = sortBy.replace(" desc", "");
	} else if (sortBy === "loggedIn") {
		isText = false;
	} else {
		isText = true;
	}

	if (isDate)
		obj = _.orderBy(
			obj,
			[
				item => {
					if (property !== undefined) {
						if (typeof item[property][sortBy] === "object" && item[property][sortBy].seconds !== undefined)
							return new Date(item[property][sortBy].seconds * 1000);
						else if (typeof item[property][sortBy] === "object" && item[property][sortBy]._seconds !== undefined)
							return new Date(item[property][sortBy]._seconds * 1000);
						else return new Date(item[property][sortBy]);
					} else {
						if (typeof item[sortBy] === "object" && item[sortBy].seconds !== undefined)
							return new Date(item[sortBy].seconds * 1000);
						else if (typeof item[sortBy] === "object" && item[sortBy]._seconds !== undefined)
							return new Date(item[sortBy]._seconds * 1000);
						else return new Date(item[sortBy]);
					}
				}
			],
			[direction]
		);
	else if (isText)
		obj = _.orderBy(
			obj,
			[
				item =>
					property !== undefined ? String(item[property][sortBy]).toLowerCase() : String(item[sortBy]).toLowerCase()
			],
			[direction]
		);
	else if (!isText) {
		obj = _.orderBy(
			obj,
			[item => (property !== undefined ? item[property][sortBy] || 0 : item[sortBy] || 0)],
			[direction]
		);
	}

	return obj;
}

function filter(obj, filters, property) {
	obj = clone(obj);
	var data = [];
	var j, k, check, targetProperty, value, checkCount;

	for (var i = 0; i < obj.length; i++) {
		checkCount = filters.length;
		for (j = 0; j < filters.length; j++) {
			for (k = 0; k < filters[j].length; k++) {
				check = filters[j][k].split(" ");
				targetProperty = check[0];
				value = check[1] || "";

				if (obj[i][targetProperty] === undefined && value === "") {
					checkCount--;
					break;
				} else if (obj[i][targetProperty] === value) {
					checkCount--;
					break;
				}
			}
		}

		if (checkCount === 0) data.push(obj[i]);
	}

	return data;
}

function group(obj, groupBy) {
	return _.chain(obj)
		.groupBy(groupBy)
		.map((data, filter) => ({ [groupBy]: filter, data }))
		.value();
}

function sum(obj, property) {
	return _.sumBy(obj, property);
}

export { filter, order, group, sum };
