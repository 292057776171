import React from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";

import Dropzone from "react-dropzone";

// Queries
import upsertClient from "../../../queries/upsert_client";

// Helpers
import * as storage from "../../../functions/storage";
import * as gqlHelpers from "../../../functions/graphql_helpers";

const namePictureContainerStyle = {
	display: "inline-block",
	textAlign: "left",
	marginTop: "100px",
	marginLeft: "calc(220px + (100% - 900px - 220px)/2)"
};

const pictureDropzoneStyle = {
	height: "96px", //"calc(96px - 54px*" + this.state.scroll + ")",
	width: "96px", //"calc(96px - 54px*" + this.state.scroll + ")",
	fontSize: "86px", //"calc(86px - 50px*" + this.state.scroll + ")",
	borderRadius: "4px",
	fontWeight: "900",
	fontFamily: "Roboto",
	textAlign: "center",
	backgroundColor: "white",
	boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)", //this.state.scroll < 0.8 ? "0 2px 4px 0 rgba(0, 0, 0, 0.5)" : "",
	border: "1px solid rgba(255, 255, 255, 0.2)", //this.state.scroll < 0.8 ? "1px solid rgba(255, 255, 255, 0.2)" : "",
	cursor: "pointer",
	display: "inline-block",
	verticalAlign: "middle",
	position: "relative"
};

const pictureStyle = {
	height: "96px",
	width: "96px",
	maxWidth: "100%"
};

const nameStyle = {
	fontSize: "86px", //"calc(86px - 50px*" + this.state.scroll + ")",
	lineHeight: "86px", //"calc(86px - 50px*" + this.state.scroll + ")",
	height: "100px", //"calc(102px - 60px*" + this.state.scroll + " - 2px)",
	color: "white", //this.state.scroll < 0.8 ? "white" : "rgba(0, 0, 0, 0.62)",
	fontWeight: "500",
	textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black", //this.state.scroll < 0.8 ? "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" : "",
	zIndex: "3",
	backgroundColor: "transparent",
	border: "none",
	outline: "none",
	display: "inline-block",
	verticalAlign: "middle",
	width: "700px",
	marginLeft: "32px"
};

const pictureUploadText = {
	position: "absolute",
	left: "0px",
	bottom: "0px",
	fontSize: "12px",
	backgroundColor: "rgba(255, 255, 255, 0.7)",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "400"
};

class ClientHeader extends React.Component {
	constructor(props) {
		super(props);
		this._onScroll = this._onScroll.bind(this);
		this._onNameChange = this._onNameChange.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onDrop = this._onDrop.bind(this);
		this._onDropCover = this._onDropCover.bind(this);
		this._onUploadComplete = this._onUploadComplete.bind(this);
		this._onpictureMouseOver = this._onpictureMouseOver.bind(this);
		this._onpictureMouseOut = this._onpictureMouseOut.bind(this);
		this._onCoverMouseOver = this._onCoverMouseOver.bind(this);
		this._onCoverMouseOut = this._onCoverMouseOut.bind(this);

		this.state = {
			scroll: 0,
			name: this.props.item !== undefined && this.props.item.name !== undefined ? this.props.item.name : "",
			pictureHover: false,
			coverHover: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		window.addEventListener("scroll", this._onScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this._onScroll);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.item !== undefined && nextProps.item.name !== undefined && nextProps.item.name !== this.state.name)
			this.setState({ name: nextProps.item.name });
	}

	_onScroll(event) {
		if (window.scrollY < 60) this.setState({ scroll: window.scrollY / 60 });
		else if (this.state.scroll < 1) this.setState({ scroll: 1 });
	}

	_onNameChange(event) {
		this.setState({ name: event.currentTarget.value });
	}

	async _onBlur(event) {
		await gqlHelpers.mutate(
			this.props.client,
			upsertClient,
			{ id: this.props.item.id, update: { name: this.state.name } },
			"Client"
		);

		this.props.refetch();
	}

	async _onUploadComplete(result, type) {
		await gqlHelpers.mutate(
			this.props.client,
			upsertClient,
			{ id: this.props.item.id, update: { [type]: result[0].url }, parentId: null },
			"Client"
		);

		this.props.refetch();
	}

	_onDrop(accepted, rejected) {
		if (accepted.length > 0) {
			this.setState({ uploading: true });
			storage.upload(accepted, "images", "picture", [], this._onUploadComplete);
			//ClientActions.upload(accepted, "picture");
		} else {
			this.props.client.mutate({
				mutation: gql`
					mutation addMessage($text: String, $type: String) {
						addMessage(text: $text, type: $type) @client
					}
				`,
				variables: {
					text:
						"Sorry, none of your files can be uploaded. Please ensure that none of the files are bigger 100MB and that all of the files are either images or videos.",
					type: "error"
				}
			});
		}
	}

	_onDropCover(accepted, rejected) {
		if (accepted.length > 0) {
			this.setState({ uploading: true });
			storage.upload(accepted, "images", "cover", [], this._onUploadComplete);

			//ClientActions.upload(accepted, "cover");
		} else {
			this.props.client.mutate({
				mutation: gql`
					mutation addMessage($text: String, $type: String) {
						addMessage(text: $text, type: $type) @client
					}
				`,
				variables: {
					text:
						"Sorry, none of your files can be uploaded. Please ensure that none of the files are bigger 100MB and that all of the files are either images or videos.",
					type: "error"
				}
			});
		}
	}

	_onpictureMouseOver() {
		this.setState({ pictureHover: true });
	}

	_onpictureMouseOut() {
		this.setState({ pictureHover: false });
	}

	_onCoverMouseOver() {
		this.setState({ coverHover: true });
	}

	_onCoverMouseOut() {
		this.setState({ coverHover: false });
	}

	render() {
		var coverpicture =
			this.props.item === undefined || this.props.item.cover === undefined || this.props.item.cover === ""
				? "/images/default_cover.jpeg"
				: this.props.item.cover;

		const headerStyle = {
			height: "400px",
			width: "100%",
			backgroundImage: "url(" + coverpicture + ")",
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
			backgroundAttachment: "fixed",
			backgroundPosition: "center center"
		};

		const coverDropzoneStyle = {
			opacity: this.state.coverHover ? "1" : "0",
			transition: "all .3s ease",
			position: "absolute",
			right: "20px",
			top: "360px",
			fontFamily: "Roboto",
			textAlign: "center",
			backgroundColor: "rgba(255, 255, 255, 0.7)",
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "12px",
			fontWeight: "400",
			padding: "8px",
			borderRadius: "4px",
			cursor: "pointer",
			width: "180px",
			overflowX: "hidden"
		};

		return (
			<div style={headerStyle} onMouseEnter={this._onCoverMouseOver} onMouseLeave={this._onCoverMouseOut}>
				<div style={namePictureContainerStyle}>
					<Dropzone
						style={pictureDropzoneStyle}
						onDrop={this._onDrop}
						accept="image/*"
						maxSize={10000000}
						multiple={false}
						onMouseEnter={this._onpictureMouseOver}
						onMouseLeave={this._onpictureMouseOut}
					>
						{this.props.item.picture !== undefined ? (
							<img style={pictureStyle} src={this.props.item.picture || ""} alt={this.state.name.substr(0, 1)} />
						) : (
							<div>{this.state.name.substr(0, 1).toUpperCase()}</div>
						)}
						{this.state.pictureHover ? <div style={pictureUploadText}>Click to upload picture</div> : ""}
					</Dropzone>
					<input
						style={nameStyle}
						value={this.state.name}
						onChange={this._onNameChange}
						onBlur={this._onBlur}
						placeholder="Company name"
					/>
				</div>
				<Dropzone
					style={coverDropzoneStyle}
					onDrop={this._onDropCover}
					accept="image/*"
					maxSize={10000000}
					multiple={false}
				>
					Click to upload cover picture
				</Dropzone>
			</div>
		);
	}
}

export default withApollo(ClientHeader);
