import React from "react";

// Components
import Container from "../../../_common/Container.react";
import OuterScaffold from "../../../_common/OuterScaffold.react";
import Permissions from "../../../_common/Permissions.react";
import UserResult from "../../../_common/search/UserResult.react";

// Query
import clienGurusQuery from "../../../../queries/client_gurus";

class ClientPermissions extends React.Component {
	render() {
		return [
			this.props.components.AppBar,
			this.props.components.SideNavigation,
			this.props.components.ClientHeader,
			<OuterScaffold key="client-body" spaceForHeader={false} verticalAlign={false}>
				<Container width="1200px" maxWidth="1200px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<Permissions
						title={"ASSIGNED AGENTS"}
						subtext={"Select the agents who work on the this account."}
						type={"users"}
						query={clienGurusQuery}
						where={[{ name: "clientId", operator: "=", value: this.props.clientId }]}
						clientId={this.props.clientId}
						resultComponent={UserResult}
					/>
				</Container>
			</OuterScaffold>
		];
	}
}

export default ClientPermissions;
