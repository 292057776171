import React from "react";
import MDSpinner from "react-md-spinner";
import { withApollo } from "react-apollo";

// Form items
import m from "../../functions/m";

import * as searchHelper from "../../functions/search";

// Queries
import searchUsers from "../../queries/search_users";
import searchTags from "../../queries/search_tags";
import searchMacroWorkflowsForTicket from "../../queries/search_macros_workflows_for_ticket";

const typeStyle = {
	width: "100%",
	display: "inline-block",
	color: "rgba(0, 0, 0, 0.67)",
	fontSize: "14px"
};

const imageStyle = {
	height: "40px",
	width: "40px",
	display: "inline-block",
	verticalAlign: "middle",
	marginRight: "16px",
	borderRadius: "50%",
	cursor: "pointer",
	fontWeight: "900",
	fontFamily: "Roboto",
	fontSize: "36px",
	textAlign: "center"
};

const nameStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	cursor: "pointer",
	width: "calc(100% - 70px)",
	textAlign: "left"
};

class SearchResult extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);
		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.onClick(this.props.item);
	}

	render() {
		const primaryStyle = {
			fontSize: "16px",
			fontFamily: "Roboto",
			fontWeight: "400",
			width: "calc(100% - 32px)",
			backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.05)" : "transparent",
			transition: "all 0.3s ease",
			cursor: "pointer",
			padding: "16px"
		};

		return (
			<div style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} onClick={this._onClick}>
				{this.props.item.picture !== undefined ? (
					<img style={imageStyle} src={this.props.item.picture} alt={this.props.item.name.substr(0, 1)} />
				) : (
					""
				)}
				<div style={nameStyle}>
					{this.props.item.name || this.props.item.text}
					{this.props.hideType ? "" : <div style={typeStyle}>{this.props.item.__typename}</div>}
				</div>
			</div>
		);
	}
}

class Search extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onSelect = this._onSelect.bind(this);

		this._onSearch = this._onSearch.bind(this);
		this._onSearchResult = this._onSearchResult.bind(this);
		this._onPreloadResult = this._onPreloadResult.bind(this);

		this._onFocus = this._onFocus.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = {
			term: "",
			focus: false,
			hover: false,
			searching: false,
			defaults: this.props.items || [],
			items: this.props.items || [],
			lastStroke: new Date()
		};
	}

	componentDidMount() {
		if (this.props.preload) searchHelper.preload(this.props.type, this.props.where || [], this._onPreloadResult);
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.defaults.length === 0 && this.state.items.length === 0 && this.state.term === "")
			this.setState({ items: nextProps.items || [], defaults: nextProps.items || [] });
		else if (this.state.defaults.length === 0 && this.state.items.length === 0)
			this.setState({
				defaults: nextProps.items || [],
				items: searchHelper.find_items(this.state.term, nextProps.items, this.props.fields || []) || []
			});
	}

	_onSearchResult(items) {
		this.setState({ items, searching: false });
		if (this.props.searchResults !== undefined) this.props.searchResults(items);
	}

	_onPreloadResult(items) {
		this.setState({
			defaults: items,
			items: searchHelper.find_items(this.state.term, items, this.props.fields || []) || []
		});
	}

	async _onSearch(term) {
		var now = new Date();
		var items;
		if (this.state.lastStroke < new Date(Number(now) - 290)) {
			console.log("SEARCHING TERM " + term);
			if (this.props.type !== undefined && (this.props.min === undefined || term.length >= this.props.min)) {
				this.setState({ searching: true });

				var query = searchUsers;
				var variables = { term };
				if (this.props.type === "tags") {
					query = searchTags;
					variables.clientId = this.props.clientId;
				} else if (this.props.type === "macros workflows for tickets") {
					query = searchMacroWorkflowsForTicket;
					variables.clientId = this.props.clientId;
				}

				var { data } = await this.props.client.query({ query, variables });

				if (this.props.type === "users") data = data.searchUsers;
				else if (this.props.type === "tags") data = data.searchTags;
				else if (this.props.type === "macros workflows for tickets")
					data = data.searchMacros.concat(data.searchWorkflows);

				this.setState({ searching: false, items: data });
			} else if (term !== "") {
				items = searchHelper.find_items(term, this.state.defaults, this.props.fields || ["name", "text"]) || [];
				this.setState({ items });
				if (this.props.searchResults !== undefined) this.props.searchResults(items);
			} else if (term === "") this.setState({ items: this.state.defaults || [] });
		} else if (term !== "" && term.length < this.props.min && this.state.defaults.length > 0) {
			items = searchHelper.find_items(term, this.state.defaults, this.props.fields || ["name", "text"]) || [];
			this.setState({ items });
		}
	}

	_updateFn(event) {
		this.setState({ term: event.currentTarget.value, lastStroke: new Date() });
		var searchTerm = this._onSearch;
		var term = event.currentTarget.value;
		if (term.length < this.props.min && this.state.defaults.length > 0) searchTerm(term);
		else setTimeout(() => searchTerm(term), 300);
	}

	_onFocus() {
		this.setState({ focus: true });
		if (this.props.onFocus) this.props.onFocus();
	}

	_onBlur() {
		this.setState({ focus: false });
		if (this.props.onBlur) this.props.onBlur();
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onSelect(obj) {
		this.setState({
			term: "",
			focus: false,
			hover: false,
			items: this.state.defaults
		});
		this.props.onSelect(obj);
	}

	render() {
		const open = this.state.focus || this.state.hover ? true : false;

		const primaryStyle = {
			width: "100%",
			position: "relative",
			textAlign: "center",
			height: "56px"
		};

		const searchStyle = {
			position: "absolute",
			top: "0px",
			left: "0px",
			backgroundColor: open ? "white" : "rgba(0, 0, 0, 0.05)",
			width: "100%",
			display: "inline-block",
			textAlign: "left",
			borderRadius: open ? "2px" : "40px",
			cursor: this.props.onClick !== undefined ? "pointer" : "",
			color: "rgba(0, 0, 0, 0.87)",
			transition: "box-shadow .3s ease",
			boxShadow: open
				? "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4)"
				: "",
			zIndex: "2"
		};

		const inputStyle = {
			width: "calc(100% - 100px)",
			backgroundColor: "transparent",
			border: "none",
			fontFamily: "Roboto",
			fontSize: "16px",
			color: "inherit",
			display: "inline-block",
			verticalAlign: "middle",
			outline: "none",
			padding: "16px 0px",
			height: "16px"
		};

		const iconStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			margin: "0px 16px"
		};

		const spinnerStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			margin: "0px 16px",
			marginRight: "20px"
		};

		const listStyle = {
			display: open && !this.props.hideResults ? "inline-block" : "none",
			top: "100%",
			left: "0px",
			zIndex: "-1",
			padding: "0px",
			margin: "0px",
			fontSize: "14px",
			textAlign: "left",
			listStyle: "none",
			borderRadius: "0px",
			color: "rgba(0, 0, 0, 0.87)",
			maxHeight: "40vh",
			overflowY: "auto",
			width: "100%",
			borderTop: "1px solid rgba(0, 0, 0, 0.12)",
			marginBottom: "-4px"
		};

		const noResultsStyle = {
			fontSize: "16px",
			fontFamily: "Roboto",
			fontWeight: "400",
			width: "calc(100% - 32px)",
			backgroundColor: "transparent",
			cursor: "pointer",
			padding: "16px"
		};

		var items = [];

		if (this.state.items === undefined || this.state.items.length === 0) {
			items.push(
				<div key={"no-results-" + this.props.id} style={noResultsStyle}>
					No results found
				</div>
			);
		} else if (this.state.items !== undefined) {
			items = this.state.items.map((item, index, arr) => (
				<SearchResult
					key={"search-result-" + (item.__typename || "") + "-" + item.id}
					item={item}
					onClick={this._onSelect}
					hideType={this.props.hideType}
				/>
			));
		}

		return (
			<div style={m(primaryStyle, this)}>
				<div id={this.props.id} style={searchStyle}>
					{this.state.searching ? (
						<div style={spinnerStyle}>
							<MDSpinner size={20} singleColor="rgb(76, 167, 224)" />
						</div>
					) : (
						<i style={iconStyle} className="material-icons">
							search
						</i>
					)}
					<input
						id={"search-" + this.props.id}
						value={this.state.term === "" && !this.state.focus ? this.props.placeholder : this.state.term}
						onChange={this._updateFn}
						placeholder={this.props.placeholder}
						style={inputStyle}
						onFocus={this._onFocus}
						onBlur={this._onBlur}
						autoComplete="off"
					/>
					{this.state.items !== undefined ? (
						<ul style={listStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
							{items}
						</ul>
					) : (
						""
					)}
				</div>
			</div>
		);
	}
}

export default withApollo(Search);
