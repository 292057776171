import React from "react";

// Pages
import GenericTable from "../_common/GenericTable.react";
import Single from "./single";

// Creators
import NewMacro from "../../../_common/creation/macros/NewMacro.react";

// Query
import clientMacros from "../../../../queries/client_macros";

// Schemas
import macrosTableSchema from "../../../../schemas/client_macros";

class PageLayer extends React.Component {
	render() {
		var view = this.props.localData.view.split("_");

		switch (view[2]) {
			case "details":
				return <Single localData={this.props.localData} />;
			default:
				return (
					<GenericTable
						components={this.props.components}
						clientId={this.props.item.id}
						title={"Macros"}
						query={clientMacros}
						where={[{ name: "clientId", operator: "=", value: this.props.item.id }]}
						orderBy={"name"}
						orderByDirection={"asc"}
						schema={macrosTableSchema}
						type={"macros"}
						view={this.props.localData.view}
						creator={NewMacro}
					/>
				);
		}
	}
}

class MacrosPage extends React.Component {
	render() {
		return (
			<PageLayer
				key={"macros-page-" + this.props.item.id}
				item={this.props.item}
				components={this.props.components}
				localData={this.props.localData}
			/>
		);
	}
}

export default MacrosPage;
