function validate(rules, value) {
	var rulesValue, now;

	for (var i = 0; i < rules.length; i++) {
		rulesValue = rules[i].value;

		if (rules[i].type === "min-length" && value.length < Number(rulesValue))
			return "Must be at least " + rulesValue + " characters.";
		else if (rules[i].type === "max-length" && value.length > Number(rulesValue))
			return "Must be no more than " + rulesValue + " characters.";
		else if (rules[i].type === "min-value" && Number(value) < Number(rulesValue))
			return "Must be at least " + rulesValue + ".";
		else if (rules[i].type === "max-value" && Number(value) > Number(rulesValue))
			return "Must be no more than " + rulesValue + ".";
		else if (rules[i].type === "min-date" && Number(new Date(value)) < Number(new Date(rulesValue)))
			return "Must be at least " + rulesValue + ".";
		else if (rules[i].type === "max-date" && Number(new Date(value)) > Number(new Date(rulesValue)))
			return "Must be no more than " + rulesValue + ".";
		else if (rules[i].type === "min-days-value") {
			now = new Date();
			now.setDate(now.getDate() - Number(rulesValue));
			if (Number(new Date(value)) < now) return "Must be at least " + rulesValue + " days before today.";
		} else if (rules[i].type === "max-days-value") {
			now = new Date();
			now.setDate(now.getDate() + Number(rulesValue));
			if (Number(new Date(value)) > now) return "Must be at no more than " + rulesValue + " days after today.";
		} else if (rules[i].type === "min-months-value") {
			now = new Date();
			now.setMonth(now.setMonth() - Number(rulesValue));
			if (Number(new Date(value)) < now) return "Must be at least " + rulesValue + " days before today.";
		} else if (rules[i].type === "max-months-value") {
			now = new Date();
			now.setMonth(now.setMonth() + Number(rulesValue));
			if (Number(new Date(value)) > now) return "Must be at no more than " + rulesValue + " days after today.";
		} else if (rules[i].type === "length" && value.length !== Number(rulesValue))
			return "Must be " + rulesValue + " characters long.";
		else if (rules[i].type === "contains" && value.indexOf(rulesValue) === -1)
			return 'Must contain "' + rulesValue + '".';
		else if (rules[i].type === "not-contain" && value.indexOf(rulesValue) > -1)
			return 'Must not contain "' + rulesValue + '".';
		else if (rules[i].type === "type" && rulesValue === "numeric" && isNaN(value)) return "Must be a number.";
	}

	return "";
}

export default validate;
