import React from "react";
import { withApollo, Query } from "react-apollo";

import config from "../../config.public";

// Scaffolding
import { DataTableGenericSection } from "./datatable";
import SectionCard from "./SectionCard.react";

import SettingsRow from "./SettingsRow.react";

// Form items
import Button from "./Button.react";
import Toggle from "./Toggle.react";

// Headers
import H2 from "./H2.react";

// Table Schemas
import permittedUserClientTableSchema from "../../schemas/users";

// Queries
import singlePermissions from "../../queries/single_permissions";
import upsertPermission from "../../queries/upsert_permission";
import upsertGuru from "../../queries/upsert_permitted_guru";
import upsertClient from "../../queries/upsert_permitted_guru";
import deletePermission from "../../queries/delete_permission";
import deleteAllPermissionsForClient from "../../queries/delete_permissions_for_client";

// Functions
import * as list from "../../functions/list";
import * as objects from "../../functions/objects";
import * as gqlHelpers from "../../functions/graphql_helpers";

const searchStyle = {
	width: "calc(100% -  32px)"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const emptyTicketTextStyle = {
	fontSize: "24px",
	fontWeight: "400"
};

const integrationsStyle = {
	width: "100%",
	textAlign: "left",
	margin: "24px 0px",
	display: "inline-block"
};

// Order options
const orderStyle = {
	width: "250px",
	marginLeft: "calc(100% - 300px)"
};

const agentOrderOptions = [
	{
		text: "Name",
		value: "name"
	},
	{
		text: "Email",
		value: "email"
	}
];

const nameStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "16px",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "500",
	cursor: "pointer",
	width: "200px",
	textAlign: "left"
};

const createdStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "400",
	width: "200px",
	textAlign: "center"
};

const labelStyle = {
	width: "100%",
	display: "inline-block",
	marginTop: "4px",
	fontSize: "12px",
	fontWeight: "400",
	color: "rgba(0, 0, 0, 0.87)"
};

const permissionsSectionStyle = {
	display: "inline-block",
	margin: "0px 0px 32px",
	width: "100%"
};

const deleteIntegrationButtonStyle = {
	position: "absolute",
	top: "32px",
	right: "16px"
};

var get_permission_value = (access, value) => {
	var single = access.find(item => item.value === value);
	if (single === undefined || single.value === undefined) return false;
	else return single.value;
};

class PermissionValueRow extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this.state = { value: this.props.value };
	}

	async _updateFn(field, location, value) {
		this.setState({ value });

		var currentValue = this.props.permissions.find(item => item.value === field);
		if (value === false && currentValue !== undefined && currentValue.id !== undefined) {
			await gqlHelpers.mutate(
				this.props.client,
				deletePermission,
				{ id: currentValue.id, parentId: this.props.selected.userId },
				"Permission"
			);
		} else if (value === true && currentValue === undefined) {
			var update = {
				clientId: this.props.selected.clientId,
				userId: this.props.selected.userId,
				value: field
			};

			await gqlHelpers.mutate(
				this.props.client,
				upsertPermission,
				{ update, parentId: this.props.selected.userId },
				"Permission"
			);
		}

		this.props.refetch();
	}

	render() {
		return (
			<SettingsRow
				index={this.props.index}
				key={"settings-row-" + this.props.item.value}
				icon={this.props.item.icon}
				active={this.state.value}
			>
				<div>{this.props.item.text}</div>
				<Toggle
					id={this.props.item.value}
					field={this.props.item.value}
					updateFn={this._updateFn}
					value={this.state.value}
				/>
			</SettingsRow>
		);
	}
}

class PermissionsSection extends React.Component {
	render() {
		return (
			<div style={permissionsSectionStyle}>
				<H2 text={this.props.item.text} margin="16px 0px 16px 0px" width="calc(100% - 8px)" />
				{this.props.item.items.map((item, index) => (
					<PermissionValueRow
						key={"permission-" + item.value}
						index={index}
						permissions={this.props.permissions}
						selected={this.props.selected}
						item={item}
						value={get_permission_value(this.props.permissions, item.value) || false}
						refetch={this.props.refetch}
						client={this.props.client}
					/>
				))}
			</div>
		);
	}
}

class PermissionRow extends React.Component {
	render() {
		const activeStyle = {
			fontSize: "16px",
			padding: "2px",
			borderRadius: "50%",
			color: this.props.item.active ? "rgb(76, 167, 224)" : "rgba(0, 0, 0, 0.52)",
			border: "1px solid"
		};

		return (
			<SettingsRow
				index={this.props.index}
				picture={this.props.item.picture}
				name={this.props.item.name || this.props.item.email}
				updateFn={this.props.updateFn}
				subsection={this.props.item}
				integration={this.props.item}
			>
				<div style={nameStyle}>{this.props.item.name}</div>
				<div style={createdStyle}>
					<i style={activeStyle} className="material-icons">
						check
					</i>
					<span style={labelStyle}>{this.props.item.active ? "Active" : "Inactive"}</span>
				</div>
			</SettingsRow>
		);
	}
}

class Permissions extends React.Component {
	constructor(props) {
		super(props);
		this._onAdd = this._onAdd.bind(this);
		this._onClick = this._onClick.bind(this);
		this._onClose = this._onClose.bind(this);
		this._removePermission = this._removePermission.bind(this);

		var myState = {
			selected: "",
			orderBy: "name",
			items: list.order(this.props.items || [], "name")
		};

		this.state = myState;
	}

	_onClick(selected) {
		console.log("CLICKED!!!");
		console.log(selected);
		this.setState({ selected, pageOffset: window.pageYOffset });
		window.scrollTo(0, 0);
	}

	_onClose(blockSave) {
		this.setState({ selected: "" });
		var offset = this.state.pageOffset || 0;
		setTimeout(function() {
			window.scrollTo(0, offset);
		}, 10);
	}

	componentWillReceiveProps(nextProps) {
		var myState = { items: list.order(nextProps.items || [], this.state.orderBy) };
		this.setState(myState);
	}

	async _onAdd(item) {
		var obj, results;
		if (this.props.type === "users") {
			obj = { userId: item.id, clientId: this.props.clientId, value: "workflows" };
			results = await gqlHelpers.mutate(
				this.props.client,
				upsertGuru,
				{ update: obj, parentId: obj.userId },
				"Permission"
			);
			this.setState({ selected: results.data.permittedGuru });
		} else if (this.props.type === "clients") {
			obj = { userId: this.props.clientId, clientId: item.id, value: "workflows" };
			results = await gqlHelpers.mutate(
				this.props.client,
				upsertClient,
				{ update: obj, parentId: obj.userId },
				"Permission"
			);
			this.setState({ selected: results.data.permittedClient });
		}
	}

	async _removePermission(clientId, userId) {
		console.log("DELETING!");
		console.log(clientId);
		console.log(userId);
		await gqlHelpers.mutate(this.props.client, deleteAllPermissionsForClient, { clientId, userId }, "Permission");

		this._onClose(true);
	}

	render() {
		return this.state.selected === "" ? (
			<DataTableGenericSection
				clientId={this.props.clientId}
				title={this.props.title}
				query={this.props.query}
				where={this.props.where}
				orderBy={"name"}
				orderByDirection={"asc"}
				schema={permittedUserClientTableSchema}
				type={this.props.type === "users" ? "gurus" : "clients"}
				view={this.props.view}
				updateFn={this._onClick}
				search={{
					resultComponent: this.props.resultComponent,
					type: this.props.type,
					onSelect: this._onAdd
				}}
			/>
		) : (
			<SectionCard title={this.state.selected.name.toUpperCase() + " PERMISSIONS"} onBack={this._onClose}>
				<Button
					text="Remove"
					onClick={() =>
						this._removePermission(
							this.props.clientId || this.state.selected.id,
							this.props.userId || this.state.selected.id
						)
					}
					style={deleteIntegrationButtonStyle}
					type="flat"
				/>
				<Query
					query={singlePermissions}
					variables={{
						clientId: this.props.clientId || this.state.selected.id,
						userId: this.props.userId || this.state.selected.id
					}}
					pollInterval={10000}
				>
					{({ data: { userPermissions }, refetch }) => {
						if (userPermissions === undefined) return "";
						return config.permissions.map((item, index) => (
							<PermissionsSection
								key={"section-" + index}
								item={item}
								permissions={userPermissions}
								updateFn={this._setPermission}
								selected={{
									clientId: this.props.clientId || this.state.selected.id,
									userId: this.props.userId || this.state.selected.id
								}}
								refetch={refetch}
								client={this.props.client}
							/>
						));
					}}
				</Query>
			</SectionCard>
		);
	}
}

export default withApollo(Permissions);

/*
			<SectionCard title={this.props.title} subtext={this.props.subtext}>
				<Search
					placeholder={"Search " + this.props.type + " to add..."}
					id={"permissions-search"}
					onSelect={this._onAdd}
					type={this.props.type}
					min={2}
					style={searchStyle}
					resultComponent={this.props.resultComponent}
				/>

				{this.state.items.length === 0 ? (
					<div key="empty-agents" style={emptyStyle}>
						<div style={emptyTicketTextStyle}>There are currently no agents assigned to this account</div>
					</div>
				) : (
					<div style={integrationsStyle}>
						<Dropdown
							id={"order-agents"}
							label="Order"
							value={this.state.orderBy}
							items={agentOrderOptions}
							field="agents"
							updateFn={this._onOrder}
							style={orderStyle}
						/>
						{this.state.items.map((item, index, arr) => (
							<PermissionRow
								item={item}
								key={"permission-row-" + index}
								index={index}
								updateFn={this._onClick}
								type={this.props.type}
							/>
						))}
					</div>
				)}
            </SectionCard>
            */
