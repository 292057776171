import React from "react";
import { Query } from "react-apollo";
import config from "../../config.public";

// App bar
import AppBar from "../_common/AppBar.react";
import SideNavigation from "../_common/SideNavigation.react";

// Pages
import AgentHome from "./AgentHome.react";
import ManagerHome from "./ManagerHome.react";

// Helpers
import * as pages from "../../functions/pages";

// Queries
import userQuery from "../../queries/me";

class PageLayer extends React.Component {
	render() {
		var view = this.props.localData.view;

		switch (view) {
			case "home_guru":
				return <AgentHome localData={this.props.localData} user={this.props.user} item={{}} />;
			case "home_manager":
				return <ManagerHome localData={this.props.localData} user={this.props.user} item={{}} />;
			default:
				return <AgentHome localData={this.props.localData} user={this.props.user} item={{}} />;
		}
	}
}

class HomePage extends React.Component {
	render() {
		return (
			<div>
				<Query query={userQuery} variables={{ uid: this.props.localData.myUID }}>
					{({ data: { user } }) => [
						<AppBar key="app-bar" UserStore={user} text="Home" dynamic={true} />,
						<SideNavigation
							key="side-navigation"
							user={user}
							view={this.props.localData.view}
							pages={config.pages}
							userPages={pages.by_permissions(user)} //this.state.UserStore.pages
						/>,
						<PageLayer key="home-page" localData={this.props.localData} user={user} />
					]}
				</Query>
			</div>
		);
	}
}

export default HomePage;
