import React from "react";

// Components
import Container from "../../../_common/Container.react";
import OuterScaffold from "../../../_common/OuterScaffold.react";
import { DataTableGenericSection } from "../../../_common/datatable";

class GenericTable extends React.Component {
	render() {
		return [
			this.props.components.AppBar,
			this.props.components.SideNavigation,
			this.props.components.ClientHeader,
			<OuterScaffold key="client-body" spaceForHeader={false} verticalAlign={false}>
				<Container width="1200px" maxWidth="1200px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<DataTableGenericSection
						key={this.props.view + "_" + this.props.clientId}
						clientId={this.props.clientId}
						title={this.props.title}
						query={this.props.query}
						where={this.props.where}
						orderBy={this.props.orderBy}
						orderByDirection={this.props.orderByDirection}
						schema={this.props.schema}
						type={this.props.type}
						view={this.props.view}
						creator={this.props.creator}
					/>
				</Container>
			</OuterScaffold>
		];
	}
}

export default GenericTable;
