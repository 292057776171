export default async (_, props, context, info) => {
	return await context.client.firestore.query(
		{
			path: "permissions/" + props.id,
			...props
		},
		"permission",
		context,
		info,
		"Permission",
		"ROOT"
	);
};
