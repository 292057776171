import firebase from "firebase/app";
import "firebase/auth";

// Functions
import getUser from "./getUser";

export default async (_, __, context) => {
	firebase.auth().onAuthStateChanged(async user => {
		if (user) getUser(context, user);
		else context.client.writeData({ data: { loggedIn: false, view: "login" } });
	});
	return;
};
