import api from "./api";
import UIActions from "../actions/UIActions";

function find_items(query, obj, fields) {
	// Figures out items
	var items = [];
	var i, j;

	// First figures out if we should be filtering by the search
	if (query === "" || obj === undefined) {
		items = obj;
	} else {
		for (i = 0; i < obj.length; i++) {
			for (j = 0; j < fields.length; j++) {
				if (
					obj[i][fields[j]] !== undefined &&
					String(obj[i][fields[j]])
						.toLowerCase()
						.indexOf(query.toLowerCase()) > -1
				) {
					items.push(obj[i]);
					break;
				}
			}
		}
	}

	return items;
}

function preload(type, where, callback) {
	const statusCode = "preloading " + type;
	api("post", "/search/preload", { type, where }, query_completed, query_failed, {}, statusCode, callback);
}

function query(term, type, fields, where, callback) {
	const statusCode = "searching " + type;
	api("post", "/search/query", { type, term, fields, where }, query_completed, query_failed, {}, statusCode, callback);
}

function query_completed(result, callback) {
	callback(result);
}

function query_failed(error, callback) {
	callback([]);
}

function update(type, obj) {
	const statusCode = "updating " + type;

	api("post", "/search/update", { table: type, obj: obj }, completed, failed, {}, statusCode);
}

function remove(type, obj) {
	const statusCode = "updating " + type;
	api("delete", "/search/" + type + "/" + obj.id, null, completed, failed, {}, statusCode);
}

function completed() {}

function failed(error) {
	UIActions.addMessage(error, "error");
}

export { find_items, preload, query, update, remove };
