function find_error(errors, id) {
	if (!Array.isArray(errors)) return false;

	if (errors.length === 0) return false;

	for (var i = 0; i < errors.length; i++) {
		if (errors[i].id === id) {
			var errorText = errors[i].text;
			errors.splice(i, 1);
			return errorText;
		}
	}

	return false;
}

export default find_error;
