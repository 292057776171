import gql from "graphql-tag";

export default gql`
	query getClient($id: ID!) {
		client(id: $id) {
			id
			active
			name
			picture
			cover
			website
			description
			created
			queueCount
			escalateCount
			slaCount
			workflowQueueCount
			csatCheckCount
		}
	}
`;
