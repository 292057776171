import React from "react";

class Viewer extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	render() {
		const primaryStyle = {
			position: "relative",
			display: "inline-block",
			margin: this.props.margin || "0px 4px 0px 0px"
		};

		const imageStyle = {
			height: "30px",
			width: "30px",
			display: "inline-block",
			verticalAlign: "middle",
			borderRadius: "50%",
			cursor: "pointer",
			fontWeight: "900",
			fontFamily: "Roboto",
			fontSize: "36px",
			textAlign: "center"
		};

		const imageTextStyle = {
			fontSize: "15px",
			height: "15px",
			width: "30px",
			textAlign: "center",
			padding: "7.5px 0px",
			backgroundColor: "rgba(0, 0, 0, 0.62)",
			color: "white",
			cursor: "pointer",
			borderRadius: "50%",
			verticalAlign: "middle",
			display: "inline-block",
			lineHeight: "15px"
		};

		const tooltipStyle = {
			position: "absolute",
			top: "calc(100% + 14px)",
			padding: "6px 8px",
			fontSize: "10px",
			color: "white",
			backgroundColor: "grey",
			fontFamily: "Roboto",
			borderRadius: "4px",
			width: "60px",
			left: "-22px",
			textAlign: "center",
			zIndex: "2"
		};

		var name = this.props.item.name || this.props.item.email;
		if (name === undefined) name = "";

		return (
			<div style={primaryStyle}>
				{this.props.item.picture !== undefined ? (
					<img
						style={imageStyle}
						src={this.props.item.picture}
						alt={name.toUpperCase().substr(0, 1)}
						onMouseOver={this._onMouseOver}
						onMouseOut={this._onMouseOut}
					/>
				) : (
					<div style={imageTextStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
						{name.toUpperCase().substr(0, 1)}
					</div>
				)}
				{this.state.hover ? <div style={tooltipStyle}>{name}</div> : ""}
			</div>
		);
	}
}

export default Viewer;
