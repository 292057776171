import gql from "graphql-tag";

export default gql`
	query getClients($cursor: String, $where: Array, $orderBy: String, $orderByDirection: String, $pageSize: Int) {
		clients(
			cursor: $cursor
			where: $where
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			pageSize: $pageSize
		) {
			cursor {
				total
				nextCursor
				previousCursor
			}
			clients {
				id
				name
				picture
				updated
				active
				queueCount
				escalateCount
				workflowQueueCount
				csatCheckCount
				slaCount
			}
		}
	}
`;
