function set(myState, stateFields, props) {
	for (var i = 0; i < stateFields.length; i++) myState[stateFields[i]] = props[stateFields[i]] || "";
	return myState;
}

function update(myState, stateFields, props, nextProps, state) {
	for (var i = 0; i < stateFields.length; i++)
		myState[stateFields[i]] =
			props[stateFields[i]] !== nextProps[stateFields[i]] ? nextProps[stateFields[i]] || "" : state[stateFields[i]];
	return myState;
}

export { set, update };
