module.exports = {
	columns: [
		{
			text: "Ticket ID",
			size: "xs",
			value: "ticketId"
		},
		{
			text: "Guru",
			size: "xs",
			value: "user",
			type: "viewer",
			wrap: true
		},
		{
			text: "Flagger",
			size: "xs",
			value: "flagger",
			type: "viewer",
			wrap: true
		},
		{
			text: "Note",
			size: "xl",
			value: "text",
			html: true,
			wrap: true
		},
		{
			text: "Reason",
			size: "xs",
			value: "reason"
		},
		{
			text: "Date",
			size: "m",
			width: "150px",
			value: "date",
			type: "date",
			filter: true
		}
	],
	href: "#clients/{{clientId}}/tickets/{{row.ticketId}}"
};
