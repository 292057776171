import Reflux from "reflux";

var UIActions = Reflux.createActions([
	"monitorRoutes",
	"show",
	"showRouter",
	"showOverlay",
	"addMessage",
	"removeMessage",
	"addFeedUpdate",
	"removeFeedUpdate",
	"route",
	"goBack",
	"showConfirmation",
	"removeConfirmation",
	"update"
]);

export default UIActions;
