export default async (parent, props, context, info) => {
	return await context.client.firestore.query(
		{
			path: "permissions",
			where: [["userId", "==", parent.id]],
			limit: 1000
		},
		"permissions",
		context,
		info,
		"Permission",
		"User",
		parent.id
	);
};
