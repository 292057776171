function ensure_array(obj) {
	if (!Array.isArray(obj.status)) obj.status = [];
}

function has(obj, code) {
	ensure_array(obj);
	return obj.status.indexOf(code) > -1;
}

function add(obj, code) {
	ensure_array(obj);
	if (obj.status.indexOf(code) === -1) obj.status.push(code);
}

function remove(obj, code) {
	ensure_array(obj);
	const index = obj.status.indexOf(code);
	if (index > -1) {
		obj.status.splice(index, 1);
	}
}

export { has, add, remove };
