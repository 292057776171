import React from "react";

// Pages
import Login from "./Login.react";
import NewPassword from "./NewPassword.react";
import ResetPassword from "./ResetPassword.react";

class PageLayer extends React.Component {
	render() {
		var view = this.props.localData.view;

		switch (view) {
			case "login":
				return <Login localData={this.props.localData} />;
			case "login_newpassword":
				return <NewPassword localData={this.props.localData} />;
			case "login_resetpassword":
				return <ResetPassword localData={this.props.localData} />;
			default:
				return <Login localData={this.props.localData} />;
		}
	}
}

class LoginPage extends React.Component {
	render() {
		return (
			<div>
				<PageLayer localData={this.props.localData} />
			</div>
		);
	}
}

export default LoginPage;
